
.circular-progress-bar {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 10px;
  background: conic-gradient(#34a73a 0% var(--percentage), #f7f6f6 var(--percentage));
  transform: rotate(90deg);
}

.circular-progress-bar::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background: white;
  z-index: 1;
}

.circular-progress-bar p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  margin: 0;
  font-size: 18px;
  color: #34a73a;
  z-index: 2;
  font-weight: bold; 
}

.trash-icon-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}