// Custom
$Lato: 'Lato';
// Font Family

// Color
$themecolor: #FCFF00;
$darkcolor: #010101;
$black: #000000;
$white: #fff;
$gray: #D9D9D9;
$grayshadeone: #B3B3B3;
$grayshadetwo: #7D7878;
$red: #EA1414;
// Color

// font Weight
$xlbold: 900;
$exbold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$normal: 400;
// font Weight

$base: '../images/';
$fontbase: 1rem;

@mixin list-style-none {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Max width
$width: 100%;
$maxWidth: 1440px;
// Max width


