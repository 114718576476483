@keyframes load {
  0% {
    left: 0;
    width: 0px;
  }

  50% {
    left: 150px;
    width: 150px;
  }

  100% {
    left: 300px;
    width: 0px;
  }
}

.loadingContainer {
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: start;
  line-height: 35px;
  font-weight: bolder;
  font-size: 25px;
  color: yellow;
  align-items: center;
  background-color: transparent;
}

.loader {
  position: relative;
  width: 200px;
  min-height: 8px;
}

/* .loader::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 300px;
  min-height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
}

.loader::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 30px;
  min-height: 8px;
  background-color: orange;
  animation: load 1.5s linear infinite;
} */
