/* Custom modal css open */
.modalContainer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  height: 100vh;
}
.modalContainer.show {
  opacity: 1;
  pointer-events: visible;
}
.modalContent {
  border-radius: 8px;
  width: 460px;
  max-height: 100vh;
  max-width: 96%;
  background-color: #ffffff;
  color: #000;
  overflow-y: scroll;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
}
.modalContainer.show .modalContent {
  transform: translateY(0);
}
.modalHeader {
  background: #fcff00;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  margin-bottom: 14px;
}
.modalTitle {
  margin: 0;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  text-align: center;
  color: #1e1e1e;
}
.CloseBtn {
  padding: 0 !important;
  background: none;
  border: 0;
  width: 28px;
}
.modalBody {
  padding: 25px 24px;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: center;
  padding: 24px;
}
.modalFooter .PrimaryBtn {
  background-color: #fff;
  border-radius: 6px;
  color: #000;
  padding: 10px 24px;
  width: auto;
  height: auto;
  border: 1px solid #000;
  margin-left: 14px;
}
.modalFooter .SecondaryBtn {
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  padding: 10px 24px;
  width: auto;
  height: auto;
  border: 1px solid #000;
  margin-left: 14px;
}
/* Custom modal css close */

.modalBody .red {
  margin: 0;
}
.modalBody h5 {
  font-weight: 700;
  font-size: 20px;
  color: #1e1e1e;
}
.refund-error {
  border: 0.5px solid #b3b3b3;
  border-radius: 10px;
  padding: 17px 20px;
}

.refund-error  p {
    font-weight: 400;
font-size: 18px;
}
.modaHeader {
    background: #FCFF00;
border-radius: 8px 8px 0px 0px;
padding: 23px 22px;
}
.modaHeader h5 {
    font-weight: 700;
font-size: 21px;
}
.active_role{
  background-color: #000;
  border-color: #000;
  color: #fff;
}

/* Chrome, Safari, Edge, Opera */
.modalContainer input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.modalContainer input[type=number] {
  -moz-appearance: textfield;
}