.detail-activity {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin: 20px;
}

/* Header Section */
.header {
  border-bottom: 3px solid #e0e0e0;
  padding-bottom: 15px;
  margin-bottom: 25px;
}

.header h2 {
  font-size: 30px;
  color: #1f1f1f;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.header h3 {
  font-size: 18px;
  color: #555;
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
}

/* Time Info Section */
.time-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.time-card {
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  width: 48%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  /* transition: box-shadow 0.3s ease, transform 0.3s ease; */
}


.time-label {
  font-size: 16px;
  color: #888;
  margin-bottom: 5px;
}

.time-value {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

/* Plates Section */
.plates-section {
  display: flex;
  justify-content: space-between;
}

.plates {
  width: 48%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.plates h4 {
  font-size: 22px;
  color: #1f1f1f;
  margin: 0;
  padding: 10px 0;
  font-weight: 600;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 1;
}

.plates ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px; /* Limit height for scrolling */
  overflow-y: auto; /* Enables scrolling */
}

.plates li {
  font-size: 16px;
  color: #555;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  transition: color 0.3s ease;
}

.plates li:hover {
  color: #333;
}

.plates li:last-child {
  border-bottom: none;
}

.plates li::before {
  content: '•';
  color: #1f1f1f;
  font-size: 20px;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}