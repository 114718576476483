.primary-menu-container {
    @media screen and (max-width:992px) {
        // width: 330px !important;
        width: 100% !important;
    }
    @media screen and (max-width:1300px) {
        width: 250px;

    }

    &.showmenu {
        transition: all .3s ease-in-out;
        -webkit-transform: translateX(0) !important;
        transform: translateX(0) !important;
    }

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: #25252500;
    z-index: 0;
    width: 308px;
    transition: all .3s ease-in-out;
    -webkit-transform: translateX(0);
    transform: translateX(-100%)
    
}

Header {
    .navbar-dark {
        width: 100%;
    }

    .sidebar-container {
        width: 100%;
        background: #252525;
        height: 100%;
        padding-top: 124px;

        @media screen and (max-width:767px) {
            // width: 100%;
            width: 330px;
            padding-top: 84px;
        }
    }

    .navbar-nav {
        li {
            a {
                font-family: 'Lato';
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF !important;
                padding: 14px 55px !important;
              
                @media screen and (max-width:1300px) {
                    padding: 14px 40px !important;
                }
                @media screen and (max-width:767px) {
                    padding: 14px 20px !important;
                }
            }
        }

        .active {
            background-color: #FCFF00;
            border-right: 1px solid #000;

            a {
                color: #000 !important
            }
        }
    }

    .navbar-toggler {
        border: none;
        padding: 0;

        &:focus-visible {
            border: none;
            outline: none;
        }

        &:focus {
            box-shadow: none;
        }

        img {
            max-width: 26px;
            object-fit: contain;
            width: 100%;
            @media screen and (max-width:767px) {
                max-width: 23px;
                object-fit: contain;
            }
        }
    }
}

.MenuWidth {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // transition: all .3s ease-in-out;
    // transform: translateX(-68%);
    z-index: 1;

    @media screen and (max-width:992px) {
        // display: unset !important;
        // margin-left: 0 !important;
        // transition: none !important;
        // transform: none !important;
        padding-right: 9px;
    }

    h4 {
        color: #fff;
        font-size: 20px;
        padding: 0 30px;

        @media screen and (max-width:992px) {
            display: none;
        }
    }
}


.navbar-brand {
    margin-right: 0;
    @media screen and (max-width:992px) {
        margin-right: auto;
    }
}

.primary-menu-container.showmenu+ {
    .MenuWidth {
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
     
        h4 {
            color: #fff;
            font-size: 20px;
            padding: 0 30px;
        }
    }
}

.dropdown-text {
    @media screen and (max-width:992px) {
        display: none;
    }
}