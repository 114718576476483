// node_modules css

@import 'bootstrap/dist/css/bootstrap.min.css';
// node_modules css
@import '../theme/global.scss';
@import '../theme/variable.scss';

@import url("../utils/modal/modal.css");
@import url("../utils/NoResultFound/noResultFoundStyle.scss");



// @import '../css/layout.css';

body {
  // font-family: $SFProText, sans-serif;
  font-family: 'Lato', sans-serif;
  font-size: $fontbase;
  color: $black;
}