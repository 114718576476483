/* login page css open */
.WhiteBackground {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  /* padding: 60px 0 0 0; */
}
.WhiteBackground .Flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100vh;
  max-width: 1900px;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
}
.LeftSection,
.RightSection {
  width: 50%;
}
.RightSectionInner {
  width: 540px;
  max-width: 100%;
  padding-left: 100px;
  box-sizing: border-box;
}
.RightSectionInner p {
  margin-top: 10px;
}
.BrandLogo {
  width: 186px;
}
@media screen and (max-width:767px) {
  .BrandLogo {
    margin: 0 auto;
    display: flex;
  }
}
.LeftSection {
  padding-left: 100px;
  padding-top: 20px;
}
.LeftSection h3 {
  margin-top: 12px;
  margin-bottom: 70px;
}
.LoginImageBox {
  width: 100%;
  max-width: 570px;
  position: relative;
}
.LeftSection .LoginImage {
  position: relative;
  padding-top: 100%;
}
.LeftSection .LoginImageBox .YellowCircleRight {
  position: absolute;
  bottom: -24px;
  right: -80px;
  width: 240px;
  height: 190px;
  background: url("../images/img-yellow-left-circle.svg") no-repeat;
  background-size: contain;
  z-index: 1;
}
.YellowCircleLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 170px;
  background: url("../images/img-yellow-right-circle.svg") no-repeat;
  background-size: contain;
  z-index: 1;
}
.LeftSection .LoginImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.FormBg .form-label {
  font-size: 12px;
  line-height: 0%;
  color: #010101;
  opacity: 0.7;
  margin: 30px 0 14px 0;
  width: 100%;
}
.FormBg .InputStyle {
  width: 100%;
  height: 55px;
  border: 1px solid #B3B3B3;
  border-radius: 10px;
  background-color: #f1f1f1;
}
.FormBg .InputStyle:focus {
  background-color: #fff;
}
.FormBg .PasswordStyle {
  width: 100%;
  height: 52px;
  border-radius: 10px;
  background-color: #f1f1f1;
  border: 0;
}
.FormBg  .passwordbtn {
  border: 0;
  background-color: transparent;
  width: 25px;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 0 18px 0 0;
  object-fit: contain;
}
.FormBg  .PasswordStyle:focus {
  border: 1px solid #b3b3b3 !important;
  background: #fff !important;
}
.FormBg .PasswordStyle:focus {
  background-color: #fff;
  border: 0;
  box-shadow: none;
}
.FormBg .flex-password {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  /* border: 1px solid #B3B3B3; */
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
/* .passwordbtn {
  border: 0;
  background-color: transparent;
  width: 28px;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 0 18px 0 0;
  width: 100%;
   object-fit: contain;
}  */
/* .passwordbtn img {
  width: 24px;
}  */
.FormBg .form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}
.FormBg label {
  font-size: 14px;
  color: #838383;
}
.Terms {
  font-size: 14px;
  text-decoration-line: underline;
  color: #010101;
  font-weight: 600;
  margin-left: 5px;
}
.Terms:hover,
.Terms:focus {
  color: #010101;
}
.ForgotPassword {
  font-weight: 600;
  font-size: 12px;
  color: #010101;
  text-decoration: none;
  margin-bottom: 60px;
  width: auto;
  display: inline-block;
}
.ForgotPassword:hover,
.ForgotPassword:focus {
  color: #010101;
}
.btn.BtnDark {
  width: 100%;
  height: 55px;
  background-color: #000000;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 1px;
}
.btn.BtnDark:hover,
.btn.BtnDark:focus {
  background-color: #000000;
  color: #ffffff;
}
/* login page css close */

/* welcome page css open */
.WelcomeBg {
  background-color: #f6f7f9;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WelcomeBox {
  max-width: 1100px;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  text-align: center;
  padding: 50px 20px;
  box-sizing: border-box;
}
.WelcomeImg {
  max-width: 240px;
  width: 100%;
  margin-bottom: 40px;
}
.WelcomeBox h1 {
  margin-bottom: 20px;
}
.WelcomeBox p {
  max-width: 838px;
  width: 100%;
  margin: 0 auto 50px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.BtnWhite {
  width: 298px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 8px 30px #0000001a;
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: #010101;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto;
}
.blankBtn {
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  height: 55px;
  background: #ffffff00;
  border: 1px solid #000000;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  color: #000000;
  margin: 0 0 0 10px;
}
.blankBtn:hover {
  background: #ffffff00;
  border: 1px solid #000000;
  color: #000000;
}
.BtnWhite img {
  width: 48px;
  margin-left: 30px;
  transition: all 0.3s ease-in-out;
}
.BtnWhite:hover {
  color: #010101;
}
.BtnWhite:hover img {
  transform: translateX(5px);
  transition: all 0.3s ease-in-out;
}
.BtnWhiteTransparent {
  width: 100%;
  min-height: 55px;
  background: #ffffff;
  /* box-shadow: 0px 8px 30px rgb(0 0 0 / 10%); */
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: #010101;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  text-decoration: none;
  margin: 0 auto;
}
/* welcome page css close */

/* header css open */
.Header {
  width: 100%;
  height: 124px;
  background: #252525 url("../images/header-background.png") no-repeat;
  background-size: cover;
  padding: 0 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  
}
@media screen and (max-width:1536px) {
   .Header {
    height: 100px;
   }   
}

  @media screen and (max-width: 1300px) {
    .Header {
      padding: 0 40px;
    }  
}
@media screen and (max-width: 767px) {
  .Header {
    padding: 0 20px;
  }
}
.Header .BrandLogo {
  max-width: 96px;
}
.Header .ProfileImage {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #ffe144;
  margin-right: 14px;
}
@media screen and (max-width: 992px) {
  .Header .ProfileImage {
    margin-right: 0;
  }
}
.Header .dropdown-menu {
  min-width: 214px !important;
  background: #252525 !important;
  margin-top: 45px !important;
  position: absolute;
  top: 50px;
  right: -20px;
}
@media screen and (max-width: 767px) {
  .Header .dropdown-menu {
    left: -119px;
    right: 0;
  }
}
.Header .dropdown-menu::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 10px solid #252525;
  position: absolute;
  top: -10px;
  right: 20px;
}
.Header .dropdown-toggle {
  background: none !important;
  border: none !important;
  display: flex;
  text-align: left;
}
.Header .dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}
.Header .dropdown-toggle::after {
  content: "";
  border: none !important;
}
.Header .dropdown-toggle-arrow {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  bottom: -11px;
  left: 5px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
}
.Header .dropdown-toggle-arrow::before,
.Header .dropdown-toggle-arrow::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 3px;
  background-color: #fff;
  transition: 0.4s ease;
}
.Header .dropdown-toggle-arrow::after {
  content: "";
  position: absolute;
  transform: rotate(90deg);
  top: -5px;
  left: 5px;
}
.Header .dropdown-toggle.show .dropdown-toggle-arrow {
  bottom: -2px;
}
.Header .dropdown-toggle.show .dropdown-toggle-arrow::before {
  transform: translate(10px, 0);
}
.Header .dropdown-toggle.show .dropdown-toggle-arrow::after {
  transform: rotate(90deg) translate(10px, 0);
}

.Header .dropdown-item {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 8px 16px;
}
.Header .dropdown-item:hover,
.Header .dropdown-item:focus {
  background: #000 !important;
}
.Header .dropdown-item img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  text-align: right;
}
.Header .dropdown-toggle h5 {
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-right: 10px;
}
.Header .dropdown-toggle h6 {
  font-size: 12px;
  line-height: 18px;
  color: #fff;
}
/* header css close */

/* training css open */
.TrainingSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 60px 60px;
  width: 100%;
  box-sizing: border-box;
}
.TrainingLeft {
  width: calc(100% - 450px);
  padding-right: 70px;
}
.ObjectWrapper {
  width: 100%;
  padding-top: 56.5%;
  position: relative;
}
.TrainingLeft object {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
.TrainingRight {
  width: 450px;
  padding-right: 100px;
  box-sizing: border-box;
}
.TrainingRight h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #838383;
  margin: 0 0 48px 0;
}
.TrainingList {
  margin: 0;
  padding: 0;
  list-style: none;
}
.TrainingList li {
  border-bottom: 1px solid #d7d7d7;
  padding: 14px;
  transition: all 0.3s ease-in-out;
}
.TrainingList li:hover {
  background: #f1f1f1;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #f1f1f1;
}
.TrainingList li:hover h4 {
  color: #000;
}
.TrainingList li:last-child {
  border-bottom: 0px solid #838383;
  padding-top: 24px;
}
.TrainingList li:last-child:hover {
  background: #ffff;
}
.TrainingList li h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #010101;
  margin: 0 0 10px 0;
  cursor: pointer;
}
.TrainingList li p {
  font-weight: 400;
  font-size: 12px;
  color: #010101;
  margin: 0;
  padding: 0;
}
.TrainingList li .BtnWhite {
  margin: 0;
  padding: 0;
  height: auto;
  width: 100%;
  justify-content: flex-start;
  box-shadow: none;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  color: #010101;
}
.TrainingList li .BtnWhite img {
  width: 40px;
}
/* training css close */

/* quiz css open */
.QuizSection {
  padding: 40px 60px;
  width: 100%;
  max-width: 1580px;
  margin: 0 auto;
}
.ProgressBar {
  width: 100%;
  background-color: #d9d9d9;
  height: 3px;
  position: relative;
  margin: 30px 0;
}
.ProgressHandle {
  position: absolute;
  top: -1px;
  width: 20%;
  height: 4px;
  background-color: #fcff00;
}
.QuizBox {
  width: 100%;
  padding: 0 100px;
}
.QuizBox h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #010101;
  margin: 0 0 38px 0;
}
.QuizBox p {
  max-width: 100%;
  width: 770px;
  margin-bottom: 70px;
}
.QuizOptions {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  width: 100%;
  margin: 0;
  padding: 0;
}
.QuizOptions li {
  width: 50%;
  margin-bottom: 40px;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #010101;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:before {
  border: 1px solid #010101;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #010101;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.NextPreBtn {
  display: flex;
  margin-top: 100px;
}
.DarkBtn {
  max-width: 150px;
  min-width: 150px;
  width: 100%;
  height: 55px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  margin-right: 40px;
}
.DarkBtn:hover,
.DarkBtn:focus,
.DarkBtn:active {
  color: #ffffff;
  background: #000000;
  border: 1px solid #000000;
}
.LightBtn {
  width: 100%;
  height: 55px;
  background: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  color: #000;
  border: 1px solid #d9d9d9;
}
.LightBtn:hover,
.LightBtn:focus,
.LightBtn:active {
  color: #000;
}
.QuizBoxSubmit {
  max-width: 764px;
  width: 100%;
  margin: 60px auto 0;
  text-align: center;
}
.QuizBoxSubmit h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #010101;
  margin: 0 0 40px 0;
}
.QuizBoxSubmit h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #010101;
  margin: 0 0 40px 0;
}
.QuizBoxSubmit p {
  max-width: 764px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #838383;
  margin: 80px 0 0 0;
}
.QuizBoxSubmit.QuizBoxSubmitFlex {
  align-items: center;
  display: flex;
  height: calc(100vh - 230px);
}
/* quiz css close */

@media (max-width: 1366px) {
  .WelcomeBg {
    padding: 40px 0;
  }
  .WelcomeImg {
    max-width: 200px;
  }
}
@media (max-width: 1280px) {
  .LeftSection {
    padding-left: 50px;
  }
}
@media (max-width: 1160px) {
  .LoginImageBox {
    margin-left: 50px;
    max-width: 370px;
  }
  .RightSectionInner {
    padding-left: 40px;
    padding-right: 60px;
  }
}
@media (max-width: 992px) {
  .LeftSection {
    padding-left: 0;
    width: 100%;
  }
  .LeftSection h3 {
    margin-bottom: 0px;
  }
  .LoginImageBox {
    display: none;
  }
  .RightSection {
    width: 80%;
  }
  .RightSectionInner {
    padding: 0;
    width: 100%;
  }
  .GoogleBtn,
  .FacebookBtn {
    width: 44%;
  }
  .WelcomeBox {
    margin: 0 40px;
  }
  .TrainingSection {
    padding: 20px 60px;
  }
  .TrainingLeft {
    padding-top: 30px;
    width: 100%;
    padding-right: 0;
  }
  .TrainingRight {
    width: 100%;
    padding-right: 0;
    padding-top: 60px;
  }
  .TrainingRight h3 {
    margin: 0 0 20px 0;
  }
  .QuizBox {
    padding: 50px 100px 0 100px;
  }
  .NextPreBtn {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-top: 1px solid #f1f1f1;
    margin-top: 0;
  }
  .QuizOptions {
    padding: 0 0 100px 0;
  }
}
@media (max-width: 767px) {
  .Header {
    padding: 0 20px;
    height: 84px;
  }
  .TrainingSection {
    padding: 20px 20px;
  }
  .TrainingLeft {
    padding-top: 0;
  }
  .TrainingRight {
    padding-top: 20px;
  }
  .TrainingRight h3 {
    margin: 0;
  }
  .TrainingList li {
    padding: 12px 0;
  }
  .TrainingList li h4 {
    margin: 0 0 5px 0;
  }
  .WhiteBackground .Flex {
    padding: 40px 0;
  }

  .BrandLogo {
    width: 150px;
  }
  .LeftSection {
    padding: 0 !important;
  }
  .LeftSection h3 {
    font-size: 20px;
    text-align: center;
  }
  .RightSection {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .YellowCircleLeft {
    display: none;
  }
  .WelcomeBox {
    margin: 20px 40px;
    padding: 30px 20px;
  }
  .WelcomeBox p {
    margin: 0 auto 40px;
    font-size: 18px;
    line-height: 26px;
  }
  .BtnWhite {
    height: 80px;
  }
  .QuizSection {
    padding: 40px 20px;
  }
  .QuizBox {
    padding: 0;
  }
  .QuizBox p {
    margin-bottom: 40px;
  }
  .QuizOptions li {
    margin-bottom: 30px;
    width: 100%;
  }
  .NextPreBtn {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    border-top: 1px solid #f1f1f1;
    margin-top: 0;
  }
  .DarkBtn {
    margin-right: 0;
    width: 47%;
  }
  .LightBtn {
    border: 1px solid #d9d9d9;
    width: 47%;
  }
  .QuizOptions {
    padding: 0 0 100px 0;
  }
  .QuizBoxSubmit {
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: calc(100vh - 250px);
  }
}
.payment-vendors {
  min-width: 400px;
  padding: 15px;
  max-height: 260px;
  overflow-y: auto;
}
.payment-vendors li {
  border: 0.8px solid #c1bbbb;
  border-radius: 3.2px;
  padding: 12px;
  margin-bottom: 7px;
  position: relative;
}
.payment-vendors img {
  width: 45px;
  object-fit: contain;
  margin-right: 15px;
  height: 33px;
}
.payment-vendors .round {
  position: absolute;
  width: 100%;
}

.payment-vendors .round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  right: 25px;
  position: absolute;
  top: 0;
  width: 28px;
}

.payment-vendors .round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.payment-vendors .round input[type="checkbox"] {
  visibility: hidden;
  width: 100%;
  height: 40px;
}

.payment-vendors .round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.payment-vendors .round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.FormBg .InputWrap {
  display: flex;
  align-items: center;
  position: relative;
}

.ActionImg {
  position: absolute;
  right: 10px;
  width: 24px;
}
.LeftSectionInner {
  width: 100%;
}
.LeftSectionInner .nav-link  {
  display: inline-block;
}
.LeftSection .FormBg .InputStyle {
  background: none;
}
.LeftSection  .FormBg .PasswordStyle:focus {
  border: 1px solid #b3b3b3;
  background: #fff;
}


.MuiFormControl-root,
.MuiTextField-root {
  width: 100% !important;
}
