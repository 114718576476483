@import "./variable.scss";
@import "./fonts.scss";

body {
  margin: 0;
  padding: 0;
  font-family: $Lato;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  width: 100%;
}

.delete-content {
  padding: 50px 0 32px;
  border-radius: 10px;
  width: 364px !important;
}

.modalContainer.show {
  background: #000000a8;
}

.delete-content h3 {
  margin-bottom: 8px !important;
}


.delete-content .modalFooter {
  padding-top: 0;
  padding-bottom: 0;
}

.delete-content .modalFooter button {
  width: 50%;
  height: 55px;
  border-radius: 10px;
  background: none;
  border: 1px solid #CACACA;
  color: #000;
}

// .delete-content .modalFooter button:first-child {
//   margin: 0;
//   background: #000;
//   color: #fff;
// }

.delete-popup {
  max-width: 57px;
  height: 57px;
  width: 100%;
  margin: 0 auto 20.44px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: $xlbold;
  font-size: 50px;
  line-height: 66px;
  color: #010101;
}

h2 {
  font-size: 34px;
  line-height: 40px;
  color: #010101;
  font-weight: $semibold;
}

h3 {
  font-size: 20px;
  line-height: 29px;
  color: #000000;
}

h4 {
  font-size: 28px;
  line-height: 29px;
  color: #000000;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 18px;
  }
}

p {
  font-weight: $normal;
  font-size: 14px;
  color: #010101;
}

// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: $maxWidth;
  }
}

.custom__container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}

a {
  color: #030dcf;
}

.ck__editor--customized {
  .ck {
    &.ck-toolbar {
      border: 1px solid #d9dce1;
      background: $white;
      border-radius: 4px 4px 0 0 !important;
    }
  }

  .ck-editor__editable {
    border: 1px solid #d9dce1 !important;
    border-top: 0 !important;
    border-radius: 0 0 4px 4px !important;
    min-height: 300px;
    max-height: 300px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Bootsrap overwrite

hr {
  border-color: var(--light-gray);
  opacity: 1;
}

.form-group {
  margin-bottom: 18px;

  &.inline-form-row {
    display: flex;
    justify-content: space-between;
  }
}

.ReactPhoneNumberInput {
  &.PhoneInput {
    border: 1px solid #000;
  }

  .PhoneInputCountry {
    background: #fcbe99;
    width: 85px;
    justify-content: center;
    margin-right: 0;
    border-right: 1px solid #000;

    .PhoneInputCountrySelectArrow {}
  }

  .PhoneInputInput {
    min-height: 60px;
    border: 0;
    padding: 12px 15px;

    &:focus {
      outline: 0;
    }
  }
}

.textarea-style {
  border: 1px solid #d9dce1;
  border-radius: 0.3125rem;
  color: #000000;
  font-size: 1rem;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
  width: 100%;
  height: 130px;
  padding: 12px;
  resize: none;
  // margin-bottom: 34px;

  // @media screen and (min-width:1530px) {
  //   height: 75px;
  // }

  &:focus-visible {
    box-shadow: none;
    outline: none;
  }
}

.CustomSelect {
  background: url('../images/Arrow-Down.svg') no-repeat;
  background-position: calc(100% - 14px) center;
  background-size: 16px;
  border: 1px solid #d6d6d6 !important;
  padding-right: 40px;
}

.form-control {
  // border: 1px solid #D9DCE1;
  border: none;
  border-radius: 0.3125rem;
  color: $black;
  font-size: $fontbase;
  min-height: calc(1.5em + 0.75rem + 0.5rem);

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $gray;
  }

  &.form-control-flat {
    border-radius: 0;
  }
}

.PhoneInputCountrySelectArrow {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  vertical-align: middle;
  transform: rotate(45deg);
}

.PhoneInputCountryIcon {
  margin-right: 4px;
}

.international-phone {
  position: relative;
  padding: 0;
  display: flex;
  overflow: hidden;
  border: 1px solid #d9dce1;
  border-radius: 0.3125rem;
  min-height: calc(1.5em + 0.75rem + 0.5rem);
}

.PhoneInput--focus {
  input {
    box-shadow: none;
    border-color: transparent;
    outline: none;
  }
}

.international-phone .PhoneInputCountry {
  position: relative;
  padding: 5px;
  top: 0;
  left: 0;
  background: #4d1b6c;
  width: 80px;
  display: flex;
  // height: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.3125rem 0 0 0.3125rem;
}

.international-phone select {
  border: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.international-phone .PhoneInputCountryIconImg {
  width: 20px;
}

.international-phone .PhoneInputInput {
  width: 100%;
  padding: 5px;
  border: none;
  height: 44px;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
}

textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}

select,
select.custom-select {
  appearance: none;
  // background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
  cursor: pointer;
}

.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}

.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1a1f36;
}

.invalid-feedback {
  display: block;

  // font-size: 14px;
  &:empty {
    display: none;
  }
}

.success-feedback {
  display: block;
  font-size: 14px;
  width: 100%;
  margin-top: 0.25rem;

  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1a1f36;
  margin-bottom: 10px;

  .required {}
}

.label-secondary {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 6px;

  .required {
    color: $red;
  }

  &.dark {
    color: $white;
  }
}

.btn {
  font-weight: 500;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  border-radius: 0.3125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: 0.9;
    background-color: #000000 !important;
    color: #FFFFFF !important;
  }

  &:disabled {}
}

.btn__icon {
  margin-right: 0.5rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.6875rem 1rem;
}

.tab_btn--container {
  margin-top: 15px;
}

.tab__btn {
  color: var(--text-gray);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 23px;
  border-radius: 100px;
  border: none;
  background-color: transparent;

  &.active {
    background: rgba(77, 27, 108, 0.1);
    color: var(--purple);
  }
}

.btn__default {
  color: #3c4257;
  background: $white;
  border-color: $white;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
    0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);

  &:hover,
  &:focus,
  &:active {
    color: #3c4257;
    background: $white;
    border-color: $white;
    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  }
}

.btn__link--deafult {
  color: $black;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.btn__link--danger {
  color: $red;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}

.btn__delete--icon {
  color: var(--delete);
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: var(--delete);
    text-decoration: none;
  }
}

.btn__text {
  color: var(--purple);
  padding: 0;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.card {
  border: 0;
  box-shadow: 5px 5px 20px #e5e5e5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: $black;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

// Bootsrap overwrite

// Base
.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

// Impersonate
.impersonate {
  background: #f7d9ff;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #c800ff;
    text-align: center;

    .impersonate__link {
      color: #c800ff;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}

// Impersonate

// Main
.main {
  &[role="header"] {
    padding-top: 80px;
  }

  &[role="header-with-tabs"] {
    padding-top: 104px;

    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }

  &[role="head"] {
    padding-top: 66px;
  }

  &[role="tabs"] {
    padding-top: 104px;
  }

  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

// Main

// Base

// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}

.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;

    &.modal-content-flat {
      border-radius: 0;
    }
  }

  .modal-dialog {
    max-width: 600px;

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

// React Modal Css

// Genral Css

// Login css open
.MainLoginWrapper {
  height: 100vh;
  background-color: #fff;
  padding: 60px;

  @media (max-width: 980px) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 20px;
    overflow-y: auto;
  }

  .FlexDiv {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .LeftSection {
      width: 50%;
      padding-right: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 980px) {
        width: 100%;
        height: 100%;
        padding: 50px 40px 0;
      }

      .FullwidthClass {
        width: 100%;

      }

      .BrandLogo {
        width: 186px;

        @media (max-width: 1366px) {
          width: 150px;
        }

        @media (max-width: 767px) {
          margin: 0 auto;
          display: flex;
        }
      }

      h3 {
        font-size: 20px;
        line-height: 29px;
        color: #000000;
        margin-top: 12px;
        margin-bottom: 40px;

        @media (max-width: 1366px) {
          margin-bottom: 45px;
          font-size: 16px;
        }

        @media (max-width: 767px) {
          text-align: center;
        }
      }

      .FlexCenter {
        height: calc(100% - 94px);
        display: flex;
        align-items: center;

        @media (max-width: 980px) {
          justify-content: center;
          // height: calc(100vh - 320px);
        }

        @media (max-width: 767px) {
          height: auto;
        }

        .SectionDetails {
          max-width: 600px;
          width: 100%;
          padding-left: 90px;
          box-sizing: border-box;

          @media (max-width: 1366px) {
            max-width: 540px;
            padding-left: 100px;
          }

          @media (max-width: 1533px) {
            padding-left: 0;
          }

          h2 {
            font-size: 34px;
            line-height: 31px;
            color: #010101;
            font-weight: 600;
            margin-bottom: 13px;
            font-family: 'Lato';

            @media (max-width: 1366px) {
              font-size: 30px;
            }
          }

          p {
            font-weight: 300;
            font-size: 12px;
            color: #010101;
            margin: 0;
            font-family: 'Lato';
          }

          .FormBg {
            .form-label {
              font-size: 12px;
              line-height: 15px;
              color: #010101;
              opacity: 0.7;
              margin: 0 0 8px 0;
              width: 100%;
            }

            .InputStyle {
              width: 100%;
              height: 55px;
              // border: 1px solid #B3B3B3;
              border-radius: 10px;
              // background-color: #f7f7f7;

              &:focus {
                background: #fff;
                border: 1px solid #b3b3b3;
              }
            }

            .flex-password {
              display: flex;
              align-items: center;
              width: 100%;
              height: 55px;
              border-radius: 10px;
              background-color: #fff;
              position: relative;

              .PasswordStyle {
                width: 100%;
                height: 55px;
                border-radius: 10px;
                // background-color: #f7f7f7;
                border: 0;

                &:focus {
                  background: #fff;
                  border: 1px solid #b3b3b3;
                }
              }

              .passwordbtn {
                border: 0;
                background-color: transparent;
                width: 25px;
                position: absolute;
                right: 0;
                cursor: pointer;
                margin: 0 18px 0 0;
                object-fit: contain;
              }
            }

            .back-to-login {
              color: #000;
              text-decoration: none;
            }

            .ForgotPassword {
              font-weight: 600;
              font-size: 12px;
              color: #010101;
              text-decoration: none;
              margin-bottom: 100px;
              width: auto;
              display: inline-block;

              @media screen and (max-width: 1366px) {
                margin-bottom: 35px;
              }
            }

            label {
              font-size: 14px;
              color: #838383;
            }

            .Terms {
              font-size: 14px;
              text-decoration-line: underline;
              color: #010101;
              font-weight: 600;
              margin-left: 5px;

              &:hover,
              &:focus {
                color: #010101;
              }
            }

            .PrimaryDark {
              width: 100%;
              height: 55px;
              background-color: #000000;
              border-radius: 10px;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              color: #ffffff;
              letter-spacing: 1px;
              border: 0;
            }
          }
        }
      }
    }

    .RightSection {
      width: 50%;
      height: calc(100vh - 120px);

      @media (max-width: 980px) {
        display: none;
      }

      .ImageBox {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 30px;
        }

        .Heading {
          width: 480px;
          max-width: 100%;
          font-weight: 700;
          font-size: 40px;
          line-height: 50px;
          color: #fcff00;
          position: absolute;
          top: 100px;
          left: 60px;
        }
      }
    }

    .AccountLink {
      font-weight: 400;
      font-size: 16px;
      line-height: normal;
      text-align: center;
      color: #010101;
      padding: 20px 0;

      a {
        color: #0099ef;
        text-decoration: underline;
        font-weight: 600;
      }
    }
  }
}

// Login css close

/* header css open */
.Header {
  width: 100%;
  height: 124px;
  background: url("../images/header-background.png") no-repeat;
  background-size: cover;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 9;
}

.MenuBtn {
  background: none;
  padding: 0;
  border: 0;

  &:focus,
  &:hover {
    background: none;
    outline: 0;
    border: 0;
  }
}


.CustomDrawer {
  transition: all 0.3s ease-in-out;

  .ant-drawer-header {
    min-height: 124px;
    border-bottom: 0px solid #f0f0f0;

    .ant-drawer-title {
      color: #fff;
    }
  }

  .ant-drawer-content {
    background-color: transparent;
  }

  .ant-drawer-body {
    background-color: #252525;
  }
}

.MenuBtn {
  transition: all 0.3s ease-in-out;
}

.MenuBtnSlide {
  position: relative;
  left: 188px;
  transition: all 0.3s ease-in-out;
}

/* header css close */

// Dashboard css open
.DashboardSection {
  display: flex;
  flex-wrap: wrap;
  padding: 0 26px;

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  .LeftSection {
    width: 55%;
    padding: 34px;

    @media (max-width: 980px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 34px 0;
      text-align: left;
      margin-bottom: 0;
    }

    .StatsList {
      list-style: none;
      margin: 34px 0 40px 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        margin: 14px 0 0px 0;
      }

      li {
        width: 30.333%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 50px 0 10px 0;
        background: #ffffff;
        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        position: relative;

        @media (max-width: 767px) {
          width: 48%;
          padding: 0px 0 0px 0;
        }

        &::after {
          content: "";
          background: url("../images/list-shwdow-bg.svg") no-repeat;
          position: absolute;
          bottom: -28px;
          right: 7px;
          left: 7px;
          width: calc(100% - 14px);
          height: 25px;
          background-size: 100%;
          background-position-y: bottom;
        }

        &:nth-child(3) {
          @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
            margin-top: 20px;
          }
        }

        h1 {
          font-size: 90px;
          line-height: 108px;
          color: #1e1e1e;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 10px;

          @media (max-width: 767px) {
            font-size: 64px;
            line-height: 78px;
          }
        }

        h6 {
          font-family: "Neutrif Pro";
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          text-transform: uppercase;
          color: #444444;
          border-top: 1px solid rgba(30, 30, 30, 0.2);
          padding: 24px 0 14px 0;
          width: 100%;

          @media (max-width: 767px) {
            padding: 10px 0 10px 0;
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
    }

    .MapSection {
      margin-bottom: 34px;

      iframe {
        width: 100%;
        border-radius: 14px;
      }
    }
  }

  .RightSection {
    width: 45%;
    padding: 34px;

    @media (max-width: 980px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    .LotSection {
      padding: 40px 0 60px 0;
      display: flex;
      flex-wrap: wrap;

      @media (max-width: 767px) {
        padding: 14px 0 40px 0;
      }

      .LotListing {
        width: calc(100% - 280px);
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 30px 30px;
        box-sizing: border-box;
        margin-right: 20px;
        height: 195px;
        overflow-y: auto;

        @media (max-width: 1160px) {
          width: 100%;
          margin-bottom: 20px;
          margin-right: 0;
        }

        li {
          background: #feff0d;
          border-radius: 20px;
          font-size: 14px;
          line-height: normal;
          font-weight: 500;
          text-align: center;
          color: #1e1e1e;
          padding: 7px 14px;
          margin-right: 7px;
          margin-bottom: 8px;
          border: 1px solid #eaeb00;
          display: inline-block;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          &:hover {
            background: #1e1e1e;
            border: 1px solid #1e1e1e;
            color: #fff;
          }
        }
      }

      .LotPay {
        background: #00b028;
        border-radius: 20px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 260px;

        @media (max-width: 1160px) {
          max-width: 100%;
          width: 100%;
        }

        h1 {
          font-weight: 800;
          font-size: 80px;
          line-height: 104px;
          color: #fff;
          width: 100%;
          margin-top: 20px;
          margin-bottom: 10px;

          @media (max-width: 767px) {
            font-size: 64px;
            line-height: 80px;
          }
        }

        h6 {
          font-family: "Neutrif Pro";
          font-weight: 700;
          font-size: 16px;
          line-height: 21px;
          text-transform: uppercase;
          color: #fff;
          border-top: 1px solid rgba(221, 221, 221, 0.2);
          padding: 14px 0;
          width: 100%;
        }
      }
    }

    .LotAvailable {
      .LotAvailableList {
        width: 100%;
        height: 400px;
        background: #ffffff;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        padding: 0px 0 30px 0;
        overflow-y: auto;
        margin: 20px 0 0 0;

        @media (max-width: 767px) {
          margin: 20px 0 30px 0;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;
          padding: 10px 30px;

          &:nth-child(even) {
            background: #f9f9f9;
          }

          .LotTitle {
            display: flex;
            font-family: "Neutrif Pro";
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #444444;

            img {
              width: 30px;
              margin-right: 10px;
            }
          }

          .LotLabel {
            border-radius: 5px;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 10px 30px;

            @media (max-width: 767px) {
              padding: 7px 20px;
            }
          }

          .LotLabelGreen {
            background: rgba(16, 188, 54, 0.2);
            color: #10bc36;
            transition: all 0.3s ease-in-out;
          }

          .LotLabelOrange {
            background: rgba(240, 178, 19, 0.2);
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
          }

          &:hover {
            .LotLabelGreen {
              background: rgba(16, 188, 54, 0.9);
              color: #fff;
            }

            .LotLabelOrange {
              background: rgba(240, 178, 19, 0.9);
              color: #fff;
            }
          }
        }
      }
    }
  }

  .MapDesktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .MapMobile {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }

    iframe {
      width: 100%;
      border-radius: 14px;
      margin-bottom: 20px;
    }
  }
}

// Dashboard css close

.HoneyBg {
  background: url("../images/honey-backgound.png") no-repeat !important;
  background-size: cover !important;
  background-position: center bottom;
}

.profile-bg {
  padding-top: 124px;

  @media (max-width: 1536px) {
    padding-top: 100px;
  }
}

.ProfileSection {
  // background: url("../images/bg.png") no-repeat;
  background-size: 100%;
  background-position-y: bottom;
  height: calc(100vh - 124px);
  min-height: 100%;
  padding: 0 !important;

  .ProfileBg {
    width: 100%;
    background: url("../images/profile-bg.jpg") no-repeat;
    background-size: cover;
    padding: 0 26px;
    min-height: 100px;
    cursor: pointer;

    @media (max-width:1280px) {
      min-height: 100px;
      max-height: 100px;
    }

    @media (max-width: 767px) {
      padding: 0 20px;
    }

    .wrapperprofile {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .box {
      display: block;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin: 10px;
      transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
      position: relative;
      top: 80px;
      border: 5px solid #FFE144;

      @media (max-width:1280px) {
        width: 120px;
        height: 120px;
        top: 40px;
      }

      @media (max-width:767px) {
        top: 100px;
      }
    }

    .ProfileImgBox {
      .ProfileImg {
        margin: 0 auto;
        display: block;
        width: 150px;
        height: 150px;
        position: relative;
        top: 140px;

        >img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          border: 5px solid #ffe144;
        }

        .EditBtn {
          border: 0;
          width: 42px;
          height: 42px;
          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          position: absolute;
          right: 0;
          bottom: 0;

          img {
            z-index: 9;
            position: relative;
            width: 26px;
          }
        }
      }
    }
  }

  .upload-options {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }

  .tooltiptext {
    align-items: flex-start;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, .1);
    color: #1e1e1e;
    display: flex;
    left: 50%;
    margin-left: 260px;
    margin-top: 20px;
    max-width: 100%;
    padding: 16px;
    position: absolute;
    text-align: left;
    top: 120px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    width: 356px;
    z-index: 1;

    @media (max-width:1280px) {
      top: 40px;
    }

    @media (max-width:899px) {
      top: 40px;
      width: 300px;
      margin-left: 220px;
    }

    @media (max-width:767px) {
      top: 224px;
      margin-left: 0;
    }

    img {
      width: 34px;
      margin-right: 10px;
    }
  }

  .box:hover+.tooltiptext {
    visibility: visible;
  }

  .upload-options {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    transition: background-color ease-in-out 150ms;
    padding-top: 11px;
    padding-left: 8px;

    & input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    & label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        background: url('../images/ic-edit.svg') no-repeat;
        width: 40px;
        height: 40px;
        background-size: 24px;
        top: 10px;
        left: 10px;
      }

      & span {
        display: inline-block;
        width: 50%;
        height: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
      }
    }

  }

  .js--image-preview {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% !important;
    // background: url('../images/default-profile.png');

    &::after {
      content: "";
      position: relative;
      font-size: 4.5em;
      color: rgba(230, 230, 230, 1);
      top: calc(50% - 3rem);
      left: calc(50% - 2.25rem);
      z-index: 0;
    }

    &.js--no-default::after {
      display: none;
    }

    &:nth-child(2) {
      background-image: url('http://bastianandre.at/giphy.gif');
    }
  }

  .YellowBg {
    max-width: 1099px;
    width: 90%;
    background: #fcff00;
    border-radius: 10px;
    padding: 40px;
    margin: 40px auto 0;

    >.Header {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      height: auto;
      padding: 0 30px;
      margin-bottom: 40px;

      .HeaderTitle {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #1e1e1e;
        width: 100%;
      }

      .HeaderClose {
        background: none;
        border: none;
        margin-left: auto;
        width: 40px;

        img {
          width: 100%;
        }
      }
    }

    .Paragraph {
      width: 100%;
      max-width: 733px;
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      color: #1e1e1e;
      margin: 0 auto;
    }
  }

  .LotListingWrapper {
    border: 1px solid #000;
    border-radius: 20px;
    padding: 30px 30px 30px 60px;
    margin: 60px auto 20px;
    width: 90%;
    max-width: 680px;
    background: #ffffff;

    .LotAvailableList {
      height: 350px;
      overflow-y: auto;

      @media (max-width: 767px) {
        margin: 20px 0 30px 0;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 5px 0;
        padding: 14px 14px 14px 0;

        .LotTitle {
          font-weight: 500;
          font-size: 20px;
          color: #1e1e1e;
        }
      }

      .form-group {
        display: block;
        margin-bottom: 0;
        width: 100%;
      }

      .form-group input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      .form-group label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
      }

      .form-group label:before {
        content: "";
        -webkit-appearance: none;
        background-color: #d9d9d9;
        border: 2px solid #d9d9d9;
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 5px;
      }

      .form-group input:checked+label:after {
        content: "";
        display: block;
        position: absolute;
        top: 6px;
        right: 14px;
        width: 6px;
        height: 14px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      .form-group input:checked+label:before {
        content: "";
        -webkit-appearance: none;
        background-color: #2f9e3a;
        border: 2px solid #2f9e3a;
      }
    }
  }

  .BtnListingWrapper {
    max-width: 497px;
    width: 100%;
    margin: 40px auto 0;
    display: flex;
    flex-wrap: wrap;

    a {
      width: 100%;
      background: #ffffff;
      border: 1px solid #000000;
      border-radius: 10px;
      font-weight: 500;
      font-size: 20px;
      text-align: center;
      color: #1e1e1e;
      padding: 14px 10px;
      margin: 0 0 12px 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        background: #1e1e1e;
        border: 1px solid #1e1e1e;
        color: #fff;
      }
    }
  }

  .ContactWrapper {
    width: 90%;
    max-width: 826px;
    background: #ffffff;
    box-shadow: 1px 1px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 60px auto 0;
    text-align: center;
    padding: 60px 50px;


    .MailIcon {
      width: 56px;
      margin-bottom: 10px;
    }

    p {
      max-width: 616px;
      width: 100%;
      font-size: 24px;
      text-align: center;
      color: #252525;
      margin: 0 auto;

      a {
        color: #3d7af0;
        text-decoration: underline;
      }
    }
  }
}

.ProfileSectionYellow {
  max-height: calc(100vh - 124px);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1366px) {
    display: unset;
    overflow-y: auto;
  }

  .WidthFull {
    width: 100%;
  }
}

.ProfileForm {
  max-width: 1080px;
  width: 100%;
  margin: 145px auto 0;

  .DarkBtn {
    max-width: 200px !important;
  }

  .form-field {
    padding: 0 30px;
  }

  .form-label {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: #1e1e1e;
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .form-control {
    width: 100%;
    height: 27px;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #000000;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    background: none;

    &:disabled {
      background: none !important;
    }
  }

  .form-select {
    width: 100%;
    font-size: 18px;
    line-height: 29px;
    color: #b7b7b7;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin-top: 26px;
    padding-left: 0;
  }

  .DarkBtn {
    max-width: 400px;
    width: 100%;
  }
}

.CstModalLg {
  width: 92%;
  max-width: 866px;
  max-height: 96vh;
}

.CstModalSm {
  width: 92%;
  max-width: 460px;
  max-height: 96vh;

  footer {
    padding: 24px 40px 30px 40px !important;

    .FooterFlex {
      display: block !important;
    }

    .BlackBtn,
    .WhiteBtn {
      width: 100% !important;
    }
  }
}

.CstModal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50px;
  transform: translate(-50%, -50%);
  padding: 0;
  border-radius: 14px;
  border: 0;
  position: absolute;
  background: #fff;

  header {
    width: 100%;
    min-height: 80px;
    background: #fcff00;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 14px 14px 0 0;
    padding: 14px 24px;
    flex-wrap: wrap;

    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #1e1e1e;
    }

    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #1e1e1e;

      .LocationIcon {
        height: 13px;
        width: auto;
      }
    }

    h5 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #1e1e1e;
      margin: 0;
      padding: 0 7px;
    }

    .MarTop {
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .ClockIcon {
      width: 24px;
    }

    .SendBtn {
      background: none;
      border: 0;

      img {
        height: 30px;
      }
    }
  }

  .ModayBody {
    padding: 30px 40px 0 40px;

    .ImgListing {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        width: 49%;
        position: relative;
        padding-top: 26%;
        margin-bottom: 14px;

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: cover;
          border-radius: 7px;
        }
      }
    }

    .ParaBg {
      max-width: 438px;
      width: 100%;
      margin: 0 auto;

      h4 {
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        color: #1e1e1e;
        margin: 0 0 20px 0;
      }

      ul {
        width: 300px;
        list-style: disc;
        margin: 0 auto;

        li {
          text-align: left;
          padding: 0 0 10px 0;
        }
      }
    }

    .InputStyle {
      box-sizing: border-box;
      width: 100%;
      height: 68px;
      border: 1px solid #c1bbbb;
      border-radius: 10px;
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #1e1e1e;
    }
  }

  footer {
    padding: 24px 24px 30px 24px;

    .FooterFlex {
      display: flex;
      justify-content: center;
    }

    .BlackBtn {
      width: 170px;
      height: 55px;
      background: #000000;
      border: 1px solid #000000;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      color: #ffffff;
    }

    .WhiteBtn {
      width: 170px;
      height: 55px;
      background: #fff;
      border: 1px solid #000000;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      text-align: center;
      color: #000000;
    }
  }

  .RedText {
    font-size: 16px;
    line-height: 22px;
    color: #be1818;
    margin-top: 20px;
  }

  .GreenText {
    font-size: 16px;
    line-height: 22px;
    color: #1e9114;
    margin-top: 5px;
  }
}

.ReactModal__Overlay {
  background: url("../images/modalimg.png") no-repeat !important;
  background-size: cover !important;
  // margin-top: 124px;
  z-index: 9;

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}

.RightSign {
  width: 60px;
}

.SuccessfullMsg {
  font-size: 20px;
  color: #000;
}

.HideGap {
  .ReactModal__Overlay {
    margin-top: 0;
  }
}

// ...............profile-page...............
.mainWrapper {
  z-index: 0;
  position: relative;

  .SupportSection {
    display: flex;
    justify-content: center;


    .WidthFull {
      width: 100%;
    }



    .YellowBg {
      max-width: 1099px;
      width: 100%;
      background: #FCFF00;
      border-radius: 10px;
      padding: 34px 40px;
      margin: 0 auto;

      @media (max-width:1536px) {
        padding: 25px 40px;
      }

      @media (max-width:1366px) {
        padding: 24px 30px;
      }

      @media (max-width:767px) {
        padding: 24px 20px;
      }

      >.HeaderTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        height: auto;
        padding: 0;
        margin-bottom: 42px;

        @media (max-width:1536px) {
          margin-bottom: 10px;
        }


        @media (max-width:1366px) {
          margin-bottom: 20px;
        }

        .HeaderTitle {
          font-weight: 900;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: #1E1E1E;
          width: 100%;
          margin: 0;

          @media (max-width:1366px) {
            font-size: 20px;
            line-height: 26px;
          }

          @media (max-width:767px) {
            font-size: 18px;
            line-height: 26px;
          }
        }

        .HeaderClose {
          background: none;
          border: none;
          margin-left: auto;
          width: 40px;

          img {
            width: 100%;
          }
        }
      }

      .Paragraph {
        width: 100%;
        max-width: 755px;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        color: #1E1E1E;
        margin: 0 auto;

        @media (max-width:1366px) {
          font-size: 20px;
          line-height: 26px;
        }

        @media (max-width:767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .ContactWrapper {
      width: 100%;
      max-width: 650px;
      background: #FFFFFF;
      border: 1px solid #C1BBBB;
      border-radius: 10px;
      margin: 60px auto 0;
      text-align: center;
      padding: 27px 12px;

      @media screen and (max-width:1536px) {
        margin-top: 40px;
      }

      @media screen and (max-width:767px) {
        margin: 20px auto 0;
        padding: 24px 10px;
      }

      .InputStyle {
        box-sizing: border-box;
        width: 100%;
        height: 44px;
        border: 1px solid #C1BBBB;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        text-align: left;
        color: #1E1E1E;
        padding: 0 14px;
      }

      .TextareaStyle {
        box-sizing: border-box;
        width: 100%;
        height: 170px;
        border: 1px solid #C1BBBB;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #1E1E1E;
        padding: 14px 14px;

      }

      .DarkBtn {
        display: inline-flex;
        margin: 0;
        width: 298px;
        max-width: 100%;

        @media screen and (max-width:767px) {
          position: unset !important;
          max-width: 100% !important;
          height: 55px !important;
        }
      }

      .FlexContact {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 35px;

        @media screen and (max-width:767px) {
          margin-top: 20px;
        }
      }

      .CustomMarg {
        margin: 0 50px 0 0;

        &:last-child {
          margin: 0;
        }

        @media screen and (max-width:767px) {
          margin: 20px 0 0 0 !important;
          width: 100%;
        }
      }

      p {
        max-width: 616px;
        width: 100%;
        font-size: 16px;
        text-align: center;
        color: #252525;
        margin: 0 auto;
        white-space: nowrap;

        img {
          width: 30px;
          margin-right: 10px;
          object-fit: contain;
          height: 24px;
        }

        a {
          color: #3D7AF0;
          text-decoration: underline;
        }

        .MailIcon {
          width: 30px;
          margin-right: 10px;
          object-fit: contain;
          height: 24px;
        }


      }
    }
  }

  .left-wrapper {
    margin-left: 0 !important;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
    padding-left: 50px;
    padding-right: 50px;
    width: 100% !important;

    @media screen and (max-width: 1200px) {
      margin-left: 0;
      padding-left: 50px;
      padding-right: 50px;
      width: 100% !important;
    }

    @media screen and (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .holding-feels {
    // margin-bottom: 18px;

    .form-label {
      white-space: nowrap;
      margin-right: 17px;
      margin-bottom: 0;
    }

  }

  .edit-holding-feels {
    margin-bottom: 16px;


    .form-label {
      white-space: nowrap;
      margin-right: 17px;
      margin-bottom: 0;
    }

  }

  .paddingBtm {
    @media screen and (max-width: 1536px) {
      padding-bottom: 32px;

    }
  }

  .main-wrapper {
    margin-left: 308px;
    padding-left: 55px;
    padding-right: 55px;
    min-height: calc(100vh - 124px);
    transition: all 0.3s ease-in-out;
    padding-top: 32px;
    position: absolute;
    top: 124px;
    background: #f7f7f7;
    width: calc(100% - 308px);


    @media screen and (max-width: 1536px) {
      min-height: calc(100vh - 100px);
      top: 100px;

    }

    @media screen and (max-width: 1300px) {
      padding-left: 40px;
      padding-right: 40px;
      width: calc(100% - 250px);
      margin-left: 250px;
    }

    @media screen and (max-width: 992px) {
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      margin-left: 0;
      min-height: calc(100vh - 124px);
    }

    @media screen and (max-width: 767px) {
      top: 84px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 17px;
      min-height: calc(100vh - 84px) !important;
    }

    h2 {
      font-family: Lato;
      font-weight: 900;
      font-size: 32px;
      color: #1e1e1e;
      margin-bottom: 37px;
      line-height: 38px;

      @media screen and (max-width: 1536px) {
        font-size: 25px;
        margin-bottom: 15px;
      }

      @media screen and (max-width: 767px) {
        font-size: 18px !important;
        max-width: 700px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    h3 {
      font-family: Lato;
      font-weight: 900;
      font-size: 28px;
      color: #1e1e1e;
      margin-bottom: 29px;

      @media screen and (max-width: 767px) {
        font-size: 18px !important;
        max-width: 300px;
        line-height: 22px;
        padding: 9px 0;
        margin-bottom: 0;
      }
    }

    h4 {
      font-family: Lato;
      font-weight: 900;
      font-size: 32px;
      color: #1e1e1e;
      margin-bottom: 23px;

      @media screen and (max-width: 767px) {
        font-size: 18px !important;
        max-width: 700px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
    .satas-wrapper {
      display: flex;
      margin-bottom: 50px;
    
      @media screen and (max-width: 1536px) {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }
    
      @media screen and (max-width: 767px) {
        justify-content: center;
        margin: 0;
      }
    
      li {
        background: #fff;
        box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        min-width: 300px;
        margin-right: 20px;
        position: relative;
    
        @media screen and (max-width: 1460px) {
          min-width: 220px;
        }
    
        @media screen and (max-width: 1536px) {
          min-width: 280px;
          margin-bottom: 20px;
        }
    
        @media screen and (max-width: 767px) {
          margin-bottom: 25px;
          width: 45%;
          padding: 15px;
          margin-right: 0;
        }
    
        @media screen and (max-width: 300px) {
          min-width: 100%;
        }
    
        &:first-child {
          @media screen and (max-width: 767px) {
            margin-right: 10px;
    
            @media screen and (max-width: 300px) {
              margin-right: 0;
            }
          }
        }
    
        // &:last-child {
        //   @media screen and (max-width: 767px) {
        //     width: 100%;
        //   }
        // }
    
        // &::after {
        //   content: "";
        //   background: rgb(255, 255, 255);
        //   box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.1);
        //   border-radius: 0px 0px 10px 10px;
        //   width: 85%;
        //   height: 14px;
        //   position: absolute;
        //   bottom: -14px;
        //   right: 7.5%;
        //   left: 7.5%;
    
        //   @media screen and (max-width: 767px) {
        //     height: 12px;
        //     bottom: -12px;
        //   }
        // }
    
        img {
          width: 50px;
          height: 50px;
          border-radius: 8px;
          object-fit: cover;
    
          @media screen and (max-width: 1533px) {
            width: 25px;
            height: 25px;
            border-radius: 5px;
          }
        }
    
        h1 {
          font-weight: 800;
          font-size: 50px;
          font-family: Lato;
          margin: 25px 0 20px;
          line-height: 60px;
    
          @media screen and (max-width: 1530px) {
            font-size: 45px;
            margin: 20px 0 10px;
            line-height: 40px;
          }
        }
    
        p {
          font-weight: 700;
          font-size: 16px;
          font-family: Lato;
          color: #7d7878;
          margin: 0;
    
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
        }
      }
    }
    

    .date-picker-wrapper {
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 34px 40px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;

      @media screen and (max-width: 1533px) {
        padding: 36px 50px 31px;
      }

      @media screen and (max-width: 886px) {
        justify-content: center;

      }

      @media screen and (max-width: 767px) {
        padding: 25px 15px !important;
        justify-content: space-between;
      }


      .left-block {
        max-width: 70%;
        display: flex;

        .DarkBtn {
          @media screen and (max-width:1280px) {
            min-width: 112px;
            height: 47px;
          }

          &:first-child {
            margin-right: 10px !important;
          }
        }

        @media screen and (max-width:1279px) {
          max-width: 100%;
          width: 100%;
          justify-content: space-between;
        }

        @media screen and (max-width:767px) {
          flex-wrap: wrap;
        }
      }

      li {
        position: relative;
        max-width: 171px;
        width: 100%;
        margin-right: 100px;
        background-image: url(../images/ic-calender.svg);
        background-repeat: no-repeat;
        background-size: 58px;
        background-position: inherit;
        background-position-y: center;
        height: 61px;

        @media screen and (max-width:1536px) {
          margin-right: 40px;
        }

        @media screen and (max-width:1205px) {
          margin-right: 80px;
          margin-bottom: 15px;
        }

        @media screen and (max-width:767px) {
          max-width: 50%;
          background-size: 35px;
          margin: 0;
          height: 51px;
        }

        @media screen and (max-width:300px) {
          max-width: 100%;
        }

        &:first-child {
          &::after {
            border: 1px solid #7D7878;
            content: "";
            position: absolute;
            height: 50px;
            top: 5px;
            right: -80px;

            @media screen and (max-width:1536px) {
              right: -40px;
            }

            @media screen and (max-width:300px) {
              border: none;
            }

            @media screen and (max-width:767px) {
              content: none;
            }
          }

        }

        &:nth-child(2) {
          margin-left: 50px;
          margin-right: 60px;

          @media screen and (max-width:1536px) {
            margin-right: 40px;
            margin-left: 40px;
          }

          @media screen and (max-width:767px) {
            margin: 0;
          }
        }

        &:last-child {
          margin: 0 0 0 0;
          width: 171px;
          background: none;
          display: flex;
          align-items: center;

          @media screen and (max-width: 1024px) {
            margin-top: 30px;
            margin: 0 auto;
            justify-content: center;
          }

          @media screen and (max-width: 767px) {
            text-align: center;
            margin-top: 15px;
          }
        }

        label {
          padding-left: 50px;

        }

        .date-picker {
          padding-left: 72px;
          border: none;
          height: 60px;
          width: 100%;
          font-size: 15px;
          background: none;
          padding-top: 41px;
          cursor: pointer;

          @media screen and (max-width:767px) {
            padding-top: 25px;
            padding-left: 48px;
          }

          &:focus {
            outline: none;
          }
        }

        .react-datepicker__triangle {
          left: -30px !important;
        }

        .react-datepicker__navigation-icon {
          top: 6px;
        }

        .react-datepicker__day--selected {
          background-color: #feffc5;
          color: #000;
        }

        .react-datepicker {
          .react-datepicker__navigation-icon {
            &::before {
              border-color: #000;
              border-width: 2px 2px 0 0;
            }
          }
        }

        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
          border: none !important;
        }

        .MuiButtonBase-root {
          position: absolute;
          left: -48px;
          top: 0;
          width: Calc(100% + 48px);
          height: 100%;

          svg {
            display: none;
          }

          &:hover {
            background-color: unset !important;
          }
        }

        .export-btn {
          background: #000;
          border-radius: 5px;
          color: #fff;
          padding: 16px 30px;
          white-space: nowrap;
          width: 100%;
          border-radius: 10px;

          @media screen and (max-width:767px) {
            margin: 0 auto;
            display: flex;
          }
        }

        label {
          padding-left: 72px;
          font-size: 16px;
          font-weight: 400;
          display: flex;
          align-items: center;

          @media screen and (max-width:767px) {
            padding-left: 48px;
          }

          img {
            width: 13px;
            margin-left: 10px;
          }

        }
      }

      .react-datepicker-wrapper {
        position: relative;
        top: -24px;
      }


    }

    .input-group {
      width: 400px;

      input {
        background: none;
        margin: 0;
        border: none;
      }

      button {
        height: 100%;
      }
    }

    .DarkBtn {
      margin: 0;

      svg {
        margin-right: 7px;
      }

      @media screen and (max-width:767px) {
        position: absolute;
        top: 20px;
        right: 20px;
        max-width: 138px !important;
        height: 40px;
        min-width: 138px !important;
      }
    }

    .lotsWrapper {
      margin-top: 34px;
      background: #fff;
      border: 0.5px solid #cacaca;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 340px);
      position: relative;

      @media screen and (max-width:1536px) {
        height: calc(100vh - 335px);
      }

      @media screen and (max-width:767px) {
        margin-top: 24px;
      }

      table {
        border-collapse: collapse;
        border-radius: 10px;
        background: #fff;

        thead {
          width: 100%;
          position: sticky;
          top: 0;
          z-index: 1;

          tr {
            background: #eff0f6;
            border-radius: 10px 10px 0px 0px;
            vertical-align: middle;

            th {
              border: none;
              font-family: "Lato";
              padding: 17px 30px;
              font-weight: 600;
              font-size: 14px;
              color: #7d7878;
              white-space: nowrap;
              text-align: center;

              @media screen and (max-width:1536px) {
                padding: 17px 20px;
              }

              @media screen and (max-width:992px) {
                padding: 10px 15px;
              }

              &:nth-child(2) {
                min-width: 300px;
              }

              &:last-child {
                padding: 17px 15px;
                width: 50px;

                button {
                  border: none;

                  .download-icon {
                    width: 17x;
                  }
                }
              }
            }
          }
        }

        tbody {
          width: 100%;
          border-radius: 0 0 10px 10px;

          tr {
            vertical-align: middle;

            td {
              font-weight: 400;
              font-family: "Lato";
              font-size: 14px;
              padding: 10px 30px;
              border: none;
              white-space: nowrap;
              text-align: center;

              @media screen and (max-width:1536px) {
                padding: 10px 20px;
              }

              &:nth-child(2) {
                min-width: 300px;
                word-break: break-word;
                white-space: break-spaces;
              }

              &:last-child {
                .action-loader {
                  height: 40px;
                  width: 50px;

                  svg {
                    max-width: 70px;
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              @media screen and (max-width:992px) {
                padding: 5px 15px;
              }

              a {
                // display: inline-block;
                // border: 0.5px solid #000000;
                // border-radius: 5px;
                // padding: 7px 10px;
                // margin-right: 9px;
                // width: 50px;
                // text-align: center;

                img {
                  width: 23px;
                  object-fit: contain;
                }
              }

              .DarkBtn {
                margin: 0 auto;
                height: 50px;
                position: unset !important;
              }
            }
          }
        }

        .your-lots-btn {
          border: 0.5px solid #000000;
          border-radius: 5px;
          padding: 8px 5px;
          background: none;
          margin-right: 9px;
          height: 40px;
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;

          &:last-child {
            margin-right: 0;
          }

          img {
            max-width: 23px;
            object-fit: contain;
          }

        }

        .form-switch {
          background: none !important;
        }
      }

      .license-plate {
        max-width: 100%;

        p {
          font-family: "Lato";
          font-weight: 400;
          font-size: 17px;
          line-height: 17px;
          color: #66a90f;
          background: #f8ffef;
          border: 0.5px solid #66a90f;
          border-radius: 5px;
          padding: 10px 15px;
          margin: 0 auto;
          text-align: center;
          margin: 0;
        }
      }
    }

    .add-lot-block {
      max-width: 1500px;

      @media screen and (min-width:1340px) {
        padding-top: 20px;
        padding-bottom: 0;
      }

      .marginBotm {
        margin-bottom: 33px;

        @media screen and (max-width:767px) {
          margin-bottom: 20px;
        }
      }

      .taging-block {
        display: flex;
        align-items: center;
      }

      .tagging-types {
        border: 1px solid #d9dce1;
        border-radius: 2.56px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 16px;
        height: 53px;
        margin-left: 12px;

        &:first-child {
          margin-left: 0;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    .add-lots {
      .textarea-style {
        background: none;
      }

      .css-yk16xz-control {
        padding: 11px;
        border: 1px solid #d9dce1;
        height: 56px;
        background: none;
      }

      .css-1pahdxg-control {
        padding: 11px;
        border: 1px solid #d9dce1;
        height: 56px;
        background: none;
        box-shadow: none;
      }

      .css-1pahdxg-control:focus {
        border: 1px solid #d9dce1;
        outline: none;
        box-shadow: none;
      }

      .css-1pahdxg-control:focus {
        border: 1px solid #d9dce1;
        box-shadow: none;
        outline: none;
      }

      .form-control {
        background: none;
      }

      .form-select {
        background-color: #ffffff00;
      }

      label {
        font-family: "Lato";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #1e1e1e;
        display: block;
      }

      .after-add-more {
        border: 1px solid #d9dce1;
        border-radius: 0.3125rem;
        width: 100%;

        .add-lots-feild {
          width: 90%;

          input {
            border: none;
          }
        }

        .input-group-btn {
          width: 10%;

          .add-more {
            position: absolute;
            right: 0;
            bottom: 0;
            top: 0;
            background: none;
            border: none;
            padding: 13px;
          }
        }
      }

      .add-field {
        overflow-y: auto;
        height: 130px;
        border: 1px solid #d9dce1;
        border-radius: 0.3125rem;
        padding: 10px 13px;
        margin-bottom: 20px;

        // @media screen and (min-width:1530px) {
        //   height: 75px;
        // }

        p {
          font-family: "Lato";
          font-weight: 400;
          font-size: 16px;
          color: #1e1e1e;
          margin-bottom: 10px;
          padding-bottom: 8px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          border-bottom: 1px solid #d9dce1;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .marginBtm {
        margin-bottom: 1.5rem;
      }

      .file-upload {
        position: relative;
        overflow: hidden;
        border: 1px dashed rgb(179, 179, 179);
        white-space: nowrap;
        padding: 13px 10px;
        width: 100%;
        height: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        @media screen and (max-width:767px) {
          height: 71.63px;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-size: 16px;
          font-weight: 600;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 70%;

          @media screen and (max-width:1536px) {
            font-size: 15px;
          }

        }

        img {
          width: 24px;
          object-fit: contain;
          margin-right: 8px;

        }

        label {
          font-size: 14px !important;
        }

        input {
          position: absolute;
          font-size: 50px;
          opacity: 0;
          right: 0;
          top: 0;
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }

      .DarkBtn {
        max-width: 300px;
        width: 100%;
        margin: 28px auto 0;

        @media screen and (max-width:767px) {
          position: unset !important;
        }
      }
    }

    .search-lot-wrapper {
      display: flex;

      @media screen and (max-width:767px) {
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 15px;
      }

      .search-lot {
        background: #ffffff;
        border-radius: 10px;
        width: 100%;
        display: flex;
        height: 55px;
        align-items: center;
        padding: 0 13px;
        margin-right: 15px;

        @media screen and (max-width:767px) {
          margin: 0;
          padding: 0 10px;
        }

        input {
          background: none;
          padding: 0;
        }

        .your-lot-btn {
          border: none;
          margin-top: 0;

          img {
            width: 17px;
            margin-right: 2px;
            object-fit: contain;
          }
        }
      }
    }

    .tagging-types {
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        color: #1E1E1E;
        margin-left: 9px;
        text-transform: capitalize;
      }

      .tagging-checkbox {
        position: relative;
        margin-right: 9px;

        label {
          font-family: "Lato";
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #1e1e1e;
          display: block;
        }

        input {
          position: absolute;
          width: 17px;
          height: 17px;
          z-index: 9;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          opacity: 0;
          cursor: pointer;
          border-radius: 2px;
        }

        input[type="checkbox"]+label {
          display: inline-block;
          border-radius: 6px;
          background: #dfdfdf;
          height: 17px;
          border-radius: 0;
          width: 17px;
          margin-right: 3px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          position: absolute;
          border-radius: 2px;
        }

        input[type="checkbox"]:checked+label {
          background-image: url(../images/rect-checkbox.svg);
          // background: #00B028;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }

    .download-icon {
      width: 17px;
      margin-right: 10px;
    }

    .datepicker-block {
      label {
        padding-left: 72px;
        font-size: 16px;
        font-weight: 400;
        position: relative;

        @media screen and (max-width:1536px) {
          padding-left: 57px;
        }

        @media screen and (max-width:1280px) {
          font-size: 13px;
        }

        @media screen and (max-width:767px) {
          padding-left: 50px;
        }

        &:after {
          position: absolute;
          content: "";
          height: 10px;
          width: 20px;
          background-image: url(../images/down-arrow.svg);
          background-repeat: no-repeat;
          right: -26px;
          top: 6px;
        }

        img {
          width: 13px !important;
          margin-left: 10px;
        }
      }

      .date-picker-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 !important;
        background: none;
        box-shadow: none;
        max-width: 100%;
        justify-content: space-between;

        @media screen and (max-width:767px) {
          flex-direction: column-reverse;
        }

        .date-picker {
          display: flex;
          align-items: center;

          @media screen and (max-width:767px) {
            width: 100%;
          }

          .date-picker-list {
            background: #ffffff00 url(../images/ic-calender.svg);
            background-repeat: no-repeat;
            background-size: 55px;
            background-position: inherit;
            background-position-y: center;

            @media screen and (max-width:1536px) {
              background-size: 40px;
            }

            @media screen and (max-width:767px) {
              width: 100%;
              background-size: 42px;
            }

            .react-datepicker-popper {
              z-index: 99;
            }

            input {
              padding-left: 72px;
              border: none;
              height: 58px;
              width: 100%;
              font-size: 15px;
              background: none;
              padding-top: 30px;
              cursor: pointer;

              @media screen and (max-width:1536px) {
                padding-left: 57px;
                padding-top: 25px;
              }

              @media screen and (max-width:1280px) {
                font-size: 13px;
                height: 47px;
              }

              @media screen and (max-width:767px) {
                padding-left: 50px;
              }

              &:focus {
                outline: none;
              }

            }

            .css-1okj3ks-MuiStack-root {
              width: 200px;

              @media screen and (max-width:767px) {
                width: 160px;
              }

              @media screen and (max-width:1150px) {
                width: 100px;
              }
            }

            &:first-child {
              height: 54px;
              max-width: 171px;
              width: 100%;
              margin-right: 40px;
              position: relative;

              @media screen and (max-width:1280px) {
                height: 47px;
              }

              @media screen and (max-width:767px) {
                max-width: 50%;
              }


              &::after {
                border: 1px solid #7D7878;
                content: "";
                position: absolute;
                height: 50px;
                top: 5px;
                right: -40px;

                @media screen and (max-width:1536px) {
                  top: 0px;
                  // right: -18px;
                }

                @media screen and (max-width:1280px) {
                  right: -15px;
                  top: -2px;
                }

                @media screen and (max-width:1280px) {
                  right: -30px;
                }
              }
            }

            &:nth-child(2) {
              height: 54px;
              max-width: 171px;
              width: 100%;
              margin-right: 40px;
              margin-left: 40px;

              @media screen and (max-width:1536px) {
                margin-right: 20px;
                margin-left: 20px;
              }

              @media screen and (max-width:1280px) {
                height: 47px;
              }

              @media screen and (max-width:992px) {
                margin-right: 0;
                margin-left: 20px;
              }
            }

            fieldset {
              border: none;
            }

            .MuiInputBase-root {
              button {
                opacity: 0;
                position: absolute;
                left: -46px;
              }
            }


          }

          img {
            width: 50px;

            @media screen and (max-width:767px) {
              width: 38px;
            }
          }
        }
      }

      .date-picker-btn {
        display: flex;
        align-items: center;

        @media screen and (max-width:767px) {
          width: 100%;
          margin-top: 20px;

        }

        .DarkBtn {
          @media screen and (max-width:767px) {
            max-width: 100% !important;
            width: 50%;
            position: unset !important;
          }

          img {
            width: 17px;
            margin-right: 10px;

          }

          &:last-child {
            margin-right: 0;
          }
        }

      }

      .search-lot {
        width: 100%;
        max-width: 29%;
        position: relative;
        padding-left: 10px;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        margin-left: 7px;

        // @media screen and (max-width:1280px) {
        //   margin-left: 20px;
        //   height: 47px;
        // }

        @media screen and (max-width:1279px) {
          max-width: 100%;
          width: 100%;
          margin-top: 15px;
          margin-left: 0;
          height: 47px;
        }

        @media screen and (max-width:767px) {
          margin-bottom: 15px;
          margin-top: 0;
        }

        button {
          img {
            max-width: 18px;
            width: 100%;
          }

          &:active {
            outline: none;
          }
        }

        .form-control {
          height: 55px;
          background: none;
          padding: 0;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .offence-wrapper {
      display: flex;
      align-items: flex-start;

      @media screen and (max-width:767px) {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 15px;
      }

      label {
        font-family: 'Lato';
        display: block;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #1E1E1E;
        margin-bottom: 8px;

      }

      .offence-list {
        width: 25%;
        margin-right: 33px;

        @media screen and (max-width:767px) {
          width: 100%;
          margin: 0 0 12px 0;
        }

        input {
          width: 100%;
          height: 55px;
          background: #FFFFFF;
          border: 0.8px solid #C1BBBB;
          border-radius: 10px;
          padding: 12px;

          &:focus-visible {
            outline: none;
          }

        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
        }

        &:last-child {
          label {
            height: 23px;
          }
        }
      }

      button {
        width: 18%;
        max-width: 100%;
        border-radius: 10px;
      }
    }
  }

  .form-switch {
    background: #c7c7c7 !important;
    border: none !important;

    .form-check-input {
      float: none;
      margin: 0;
      width: 33px;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 21px;
    }

    .switch input {
      display: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #D9D9D9;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;
      border: 1px solid #D9D9D9;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 22px;
      width: 22px;
      left: -1px;
      bottom: -1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
      border: 1px solid #b2b2b2;
    }

    input:checked+.slider {
      background-color: #2ab934;
      border: 1px solid #2ab934;

    }

    input:focus+.slider {
      box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(25px);
      border: 1px solid #e7e7e7;
      left: -9px;
    }

    /*------ ADDED CSS ---------*/
    .slider:after {
      content: '';
      color: white;
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
    }



    /*--------- END --------*/
  }

  .permit-wrapper {
    .permit-search {
      display: flex;
      align-items: center;
      width: 100%;

      @media screen and (max-width:767px) {
        flex-wrap: wrap;
      }

      .search-lot {
        @media screen and (max-width:767px) {
          min-width: 100%;
          margin-top: 0;
        }
      }

      .blankBtn {
        @media screen and (max-width:767px) {
          margin: 10px 0 0 0;
          // width: calc(50% - 10px);
          position: unset;
          min-width: 48%;
          max-width: 48%;

          &:last-child {
            margin-left: 12px;

            @media screen and (max-width:360px) {
              margin-left: 0;
            }
          }
        }

        @media screen and (max-width:360px) {
          max-width: 100%;
          margin-left: 0;
        }
      }

      .DarkBtn {
        @media screen and (max-width:767px) {
          margin: 10px 0 0 0;
          // width: calc(50% - 10px);
          position: unset;
          min-width: 48% !important;
          height: 55px;
          max-width: 48% !important;

          &:last-child {
            margin-left: 12px;

            @media screen and (max-width:360px) {
              margin-left: 0;
            }
          }
        }

        @media screen and (max-width:360px) {
          min-width: 100% !important;
        }
      }
    }

    .download-btn {
      margin-left: 10px;
    }

  }


  .permitlotsWrapper {
    margin-top: 23px;
    background: #fff;
    border: 0.5px solid #cacaca;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 315px);
    position: relative;

    @media screen and (max-width:1536px) {
      height: calc(100vh - 280px);
    }

    @media screen and (max-width:767px) {
      margin-top: 12px;
    }

    table {
      border-collapse: collapse;
      border-radius: 10px;

      thead {
        width: 100%;
        position: sticky;
        top: 0;

        tr {
          background: #eff0f6;
          border-radius: 10px 10px 0px 0px;
          vertical-align: middle;

          th {
            border: none;
            font-family: "Lato";
            padding: 17px 40px;
            font-weight: 600;
            font-size: 14px;
            text-align: center;
            color: #7d7878;
            white-space: nowrap;
            letter-spacing: 0.0225556px;

            @media screen and (max-width:1536px) {
              padding: 17px 20px;
            }

            @media screen and (max-width:992px) {
              padding: 10px 15px;
            }
          }
        }
      }

      tbody {
        width: 100%;
        border-radius: 0 0 10px 10px;
        max-height: calc(100vh - 220px);

        tr {
          vertical-align: middle;

          td {
            font-weight: 500;
            font-family: "Lato";
            font-size: 15.7889px;
            padding: 10px 40px;
            border: none;
            text-align: center;
            white-space: nowrap;

            @media screen and (max-width:1536px) {
              padding: 6px 20px;
            }

            @media screen and (max-width:992px) {
              padding: 9px 15px;
            }

            a {
              display: inline-block;
              border: 0.5px solid #000000;
              border-radius: 5px;
              padding: 7px 10px;
              margin-right: 9px;

              img {
                width: 23px;
                object-fit: contain;
              }
            }
          }
        }
      }

      .your-lots-btn {
        border: 0.5px solid #000000;
        border-radius: 5px;
        padding: 8px 5px;
        background: none;
        margin-right: 9px;
        height: 40px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;

        &:last-child {
          margin-right: 0;
        }

        img {
          max-width: 23px;
          object-fit: contain;
        }
      }
    }


    .license-plate {
      p {
        font-weight: 400;
        font-size: 17px;
        line-height: normal;
        color: #66a90f;
        background: #f8ffef;
        border: 0.5px solid #66a90f;
        border-radius: 6px;
        padding: 5px 12px 2px 12px;
        min-width: 84px;
        text-align: center;
        margin-bottom: 0;
        max-width: 120px;
        vertical-align: middle;
        text-transform: uppercase;
      }
    }

    .permit-lot {
      p {
        // margin-right: 10px;
        margin-bottom: 0;


      }

    }
  }

  h1 {
    font-family: "Lato";
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    color: #010101;
    margin-bottom: 22px;

    @media screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }

  .connect-strip-para {
    font-family: "Lato";
    font-weight: 300;
    font-size: 21px;
    color: #010101;
    max-width: 700px;
    line-height: 35px;
    margin-bottom: 22px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 10px;
    }
  }

  h6 {
    font-family: "Lato";
    font-weight: 400;
    font-size: 21px;
    color: #010101;
    max-width: 550px;
    line-height: 30px;

    @media screen and (max-width:767px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .accepting-payment-list {
    margin-bottom: 40px;

    li {
      font-family: "Lato";
      font-weight: 400;
      font-size: 21px;
      color: #010101;
      margin-bottom: 8px;

      @media screen and (max-width:767px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      img {
        width: 20px;
        margin-right: 18px;
      }
    }
  }

  .connectStrip {
    background: #645aff;
    border-radius: 5px;
    color: #fff;
    padding: 18px 30px;
    font-size: 16px;
    max-width: 210px;
    margin-bottom: 50px;

    @media screen and (max-width:767px) {
      margin: 0 auto;
      display: flex;
    }
  }

  .permit-para {
    font-family: "Lato";
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #a6a5a5;
  }

  .download-btn {
    img {
      width: 19px;
      height: 19px;
      object-fit: contain;
      margin-right: 10px
    }
  }

}

.offence-btn {
  width: 100%;
  text-align: end;
  // border: 1px solid #C1BBBB;
  // padding: 10px;
  // background: none;
  // height: 47px;

  .dropdown-arrow {
    left: -7px;
  }

  .add-offiences {
    display: flex;
    align-items: center;

    p {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

}

.Offencelist input[type=checkbox]+label {
  display: inline-block;
  border-radius: 6px;
  background: #dfdfdf;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;

}

.Offencelist input[type=checkbox]:checked+label {
  background-image: url(../images/rightwhite.svg);
  background-size: 105%;
  background-repeat: no-repeat;

}

.offence-block {
  position: relative;

  .Offencelist {
    max-height: 235px;
    overflow-y: auto;
    margin-top: 8px;
    padding-right: 5px;

    li {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0.8px solid #c1bbbb;
      border-radius: 3.2px;
      padding: 6px 15px;
      height: 50px;


      .vendor-checkbox {
        position: relative;
        margin-right: 10px;

        input {
          position: absolute;
          width: 25px;
          height: 25px;
          z-index: 9;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          opacity: 0;
          cursor: pointer;
        }

        label {
          font-family: "Lato";
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #1e1e1e;
          display: block;
        }
      }
    }
  }

  .show-offences {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h5 {
      font-family: 'Lato';
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
      color: #1E1E1E;
      margin: 0 11px 10px 0;

      img {
        width: 17px;
        margin-right: 7px;
      }
    }
  }
}

.offence-block-permit {
  position: relative;

  .Offencelist-permit {
    background: #fff;
    box-shadow: 0.32px 0.32px 17.92px rgb(0 0 0 / 18%);
    border-radius: 6.4px;
    position: absolute;
    z-index: 1;
    padding: 15px;
    max-width: 443px;
    left: 12px;
    right: 12px;
    top: 60px;
    max-height: 235px;
    overflow-y: auto;

    li {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0.8px solid #c1bbbb;
      border-radius: 3.2px;
      padding: 6px 15px;
      height: 50px;

      &:last-child {
        margin-bottom: 0;
      }

      .vendor-checkbox {
        position: relative;
        margin-right: 10px;

        input {
          position: absolute;
          width: 25px;
          height: 25px;
          z-index: 9;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          opacity: 0;
          cursor: pointer;
        }

        label {
          font-family: "Lato";
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #1e1e1e;
          display: block;
        }
      }
    }
  }

  .show-offences {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    h5 {
      font-family: 'Lato';
      font-weight: 600;
      font-size: 15px;
      line-height: 17px;
      color: #1E1E1E;
      margin: 0 11px 0 0;

      img {
        width: 17px;
        margin-right: 7px;
      }
    }
  }

  .add-offiences {
    p {
      margin-bottom: 0;
    }
  }
}

.Offencelist-permit input[type=checkbox]:checked+label {
  background-image: url(../images/rightwhite.svg);
  background-size: 105%;
  background-repeat: no-repeat;

}

.Offencelist-permit input[type=checkbox]+label {
  display: inline-block;
  border-radius: 6px;
  background: #dfdfdf;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;

}

.add-lots {
  .form-control {

    padding: 11px;
    border: 1px solid #d9dce1;
    height: 56px;
  }

  .modal-dialog {
    transform: translate(-50%, -50%) !important;
    top: 50%;
    left: 50%;
    position: absolute;
    max-width: 800px;
    width: 100%;

    @media screen and (max-width: 767px) {
      height: 100%;
    }
  }

}

.add-permit {
  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 100%;
    padding: 0 15px 0 0;
  }

  label {
    font-family: "Lato";
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1e1e1e;
    margin-bottom: 8px;
  }

  .form-control {
    padding: 16px 13px;
    border: 0.8px solid #c1bbbb;
    border-radius: 3.2px;
    color: #4f5e74;
    display: flex;
    justify-content: space-between;

    &::placeholder {
      color: #b7b7b7;
    }

  }

  .expiration-date {
    input {
      border: none;
      width: 100%;
      padding: 16px 13px;
      border: 0.8px solid #c1bbbb;
      border-radius: 3.2px;
      background-image: url(../images/Calender.svg);
      background-repeat: no-repeat;
      background-position: 97%;
      background-size: 20px;

      &:focus {
        outline: none;
      }

    }

    .react-datepicker__triangle {
      left: -100px !important;

    }
  }

  textarea {
    width: 100%;
    border: 1px solid #d9dce1;
    border-radius: 0.3125rem;
    color: #000000;
    padding: 12px;

    &:focus-visible {
      outline: none;
    }
  }

  // .modal-dialog {
  //   transform: translate(-50%, -50%) !important;
  //   top: 50%;
  //   left: 50%;
  //   position: absolute;
  //   max-width: 600px;
  //   width: 100%;
  // }

  .modal-header {
    display: unset;
    position: relative;
    background: #fcff00;
    border-radius: 10px 10px 0px 0px;
  }

  .add-permit {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-right: 0;
      width: 50% !important;
      max-width: 100%;
      border-radius: 10px;
      height: 55px;

      @media screen and (max-width:767px) {
        width: 100% !important;
      }
    }
  }

  .modal-title {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    color: #1E1E1E;
    width: 100%;
  }

  .btn-close {
    position: absolute;
    top: 25px;
    right: 20px;
    padding: 0;
  }

  .modal-body {
    label {
      font-family: "Lato";
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #1e1e1e;
      display: block;
    }

    .add-lots-feild {
      width: 90%;
    }

    .input-group-btn {
      width: 10%;

      .add-more {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        background: none;
        border: none;

        &:focus-visible {
          box-shadow: none;
        }
      }
    }

    .after-add-more {
      border: 1px solid #d9dce1;
      border-radius: 0.3125rem;

      input {
        border: none;
      }
    }

    .add-field {
      overflow-y: auto;
      max-height: 150px;
      border: 1px solid #d9dce1;
      border-radius: 0.3125rem;
      padding: 10px 13px;

      p {
        font-family: "Lato";
        font-weight: 400;
        font-size: 16px;
        color: #1e1e1e;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }


    .file-upload {
      position: relative;
      overflow: hidden;
      border: 1px dashed rgb(179, 179, 179);
      white-space: nowrap;
      padding: 13px 35px;
      width: 100%;

      img {
        width: 25px;
        object-fit: contain;
        margin-right: 10px;
      }

      input {
        position: absolute;
        font-size: 50px;
        opacity: 0;
        right: 0;
        top: 0;
      }

    }

    .dropdown {
      button {
        &:active {
          border: none;
        }
      }
    }

    header h1 {
      font-size: 12pt;
      color: #fff;
      background-color: #1ba1e2;
      padding: 20px;
    }

    article {
      width: 80%;
      margin: auto;
      margin-top: 10px;
    }

    .thumbnail {
      height: 100px;
      margin: 10px;
    }

  }
}

.menu--btn {
  transition: all 0.3s ease-in-out;
  transform: translateX(0) !important;
}

.paymentVendorBtn {
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  position: relative;
  width: 13px;
  height: 13px;
  display: inline-block;
  position: relative;
  bottom: -5px;
  left: -2px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);

  &::after {
    content: "";
    content: "";
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: rgb(0 0 0);
    transition: 0.4s ease;
    transform: rotate(90deg);
    top: -4px;
    left: 4px;
  }

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: rgb(0, 0, 0);
    transition: 0.4s ease;
  }
}

.paymentVendorBtn.show .dropdown-arrow {
  bottom: 2px;

  &::before {
    transform: translate(10px, 0);
  }

  &::after {
    transform: rotate(90deg) translate(10px, 0);
  }
}

.vendor-block {
  position: relative;
  margin-bottom: 5px;
  margin-top: 30px;
}

.upload-img {
  margin-top: 30px;

  @media screen and (max-width:992px) {
    margin-bottom: 20px;
  }
}

.form-select {
  width: 100%;
  max-width: 50px;
  border: none;
  padding: 0;
  background-size: 17px;
  background-position: right;

  &:focus-visible {
    outline: none;
    border: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
}

.selectlotblock {
  background: #fff;
  box-shadow: 0.32px 0.32px 17.92px rgb(0 0 0 / 18%);
  border-radius: 6.4px;
  width: 100%;
  position: absolute;
  z-index: 2;
  padding: 15px;
  max-width: 100%;
  right: 0;
  top: 0;

  .close-btn {
    max-width: 20px;
    display: flex;
    margin: 0 0 0 auto;
    width: 100%;
    cursor: pointer;
  }

  .paymentVendorlist {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 8px;
    padding-right: 5px;
  }
}

.paymentWrapper {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.paymentVendorblock {
  background: #fff;
  box-shadow: 0.32px 0.32px 17.92px rgb(0 0 0 / 18%);
  border-radius: 6.4px;
  width: 100%;
  position: absolute;
  z-index: 2;
  padding: 15px;
  max-width: 372px;
  right: 0;
  bottom: 0;


  .close-btn {
    max-width: 20px;
    display: flex;
    margin: 0 0 0 auto;
    width: 100%;
    cursor: pointer;
  }

  .paymentVendorlist {
    max-height: 250px;
    overflow-y: auto;
    margin-top: 8px;
    padding-right: 5px;

  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(208, 208, 208);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }

}

.vendor-checkbox {
  position: relative;
  margin-right: 10px;
}

.payment-vendor button img {
  width: 27px;
  object-fit: contain;
  margin-right: 7px;
}

.payment-vendor button .add-vendor {
  max-width: 130px;
}

.paymentVendorlist input {
  position: absolute;
  width: 25px;
  height: 25px;
  z-index: 9;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  opacity: 0;
  cursor: pointer;
}

.paymentVendorlist input[type="checkbox"]+label {
  display: inline-block;
  border-radius: 6px;
  background: #dfdfdf;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  position: absolute;
}

.paymentVendorlist input[type="checkbox"]:checked+label {
  background-image: url(../images/rightwhite.svg);
  background-size: 105%;
  background-repeat: no-repeat;
}

.paymentVendorlist {
  .close-btn {
    width: 20px;
    margin: 0 0 10px auto;
    display: flex;
  }

  li {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0.8px solid #c1bbbb;
    border-radius: 3.2px;
    padding: 6px 15px;
    height: 50px;

    img {
      width: 45px;
      object-fit: contain;
      margin-right: 15px;
    }
  }
}

.contact-support {
  h5 {
    max-width: 400px;
    width: 100%;
    text-align: center;
    color: #252525;
    margin: 10px auto 25px;
  }

  .CustomMarg {
    margin: 0 20px;
  }

  p {
    max-width: 616px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #252525;
    margin: 0 auto;
  }

  img {
    width: 34px;
    margin-right: 10px;
  }

  a {
    color: #3d7af0;
    text-decoration: underline;
  }
}

// Lot details css open
.paddzero {
  @media screen and (min-width:767px) {
    padding: 0 !important;
  }
}

.left-arrow {
  width: 9px;
  margin-right: 15px;
  height: 17px;
  cursor: pointer;

  @media screen and (max-width:767px) {
    width: 7px;
    margin-right: 13px;
  }
}

.addlote-title {
  @media screen and (max-width:767px) {
    height: 55px;
  }
}

.HeadingSection {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #a0a0a0;
  padding: 28px 55px;

  @media screen and (max-width:767px) {
    padding: 0 !important;
    border-bottom: none;
  }

  @media screen and (max-width:1536px) {
    padding: 15px 55px;
  }

  h4 {
    margin: 0 !important;
    padding: 0;
    color: #1e1e1e;
    font-size: 28px !important;
    font-weight: 700 !important;
  }

  .CloseBtn {
    background: none;
    border: 0;
    width: 30px;
    padding: 0;
  }
}

.DetailsWrapper {
  padding: 30px 55px;

  .margin_bottm {
    margin-bottom: 28px;
  }

  @media screen and (max-width:767px) {
    padding: 20px 0;
  }

  .DarkBtn {
    @media screen and (max-width:767px) {
      position: unset !important;
    }
  }

  .show-offences {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    h5 {
      font-family: 'Lato';
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #7D7878;
      margin-bottom: 7px;
      margin-right: 12px;

      img {
        width: 15px;
        margin-right: 7px;
      }
    }
  }

  .tagging-types {
    // width: 100% !important;
    padding: 14px !important;
  }

  .marginBotm {
    @media screen and (max-width:992px) {
      margin-bottom: 25px;
    }

    span {
      margin-right: 9px;
    }

  }

  .margintopBotm {
    margin: 33px 0;
  }

  .vendor-heaading {
    @media screen and (max-width:767px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .allowedLabel {
    color: #1e1e1e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 19px;

    @media screen and (max-width:767px) {
      font-size: 16px;
    }
  }

  .OutputLabel {
    color: #1e1e1e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 19px;

    @media screen and (max-width:767px) {
      font-size: 16px;
    }
  }

  .payment-vendor {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .vendor-list {
      display: flex;
      align-items: center;
      margin-right: 17px;
      margin-bottom: 14px;

      img {
        width: 40px;
        margin-right: 10px;
      }

      p {
        font-family: "Lato";
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #7d7878;
        margin: 0;
      }
    }
  }

  .OutputDetails {
    color: #7d7878;
    font-size: 16px;
    font-weight: 500;
    margin: 0;

    &:first-child {
      min-width: 165px;
    }

    @media screen and (max-width:767px) {
      font-size: 12px;
    }

  }

  .semicolum {
    margin: 0 15px;
  }

  .ParkingList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    row-gap: 14px;
    // align-items: flex-end;

    @media screen and (max-width:767px) {
      flex-wrap: wrap;
    }

    .posirel {
      width: 30%;
      margin-right: 14px;

      @media screen and (max-width:767px) {
        width: 27%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    li {
      width: 30%;
      margin-right: 14px;

      &:last-child {
        margin-right: 0;
      }


      // p {
      //   margin: 0 0 4px 0;
      //   font-family: 'Lato';
      //   font-weight: 400;
      //   font-size: 16px;
      //   color: #1E1E1E;
      // }

      .ImgBox {
        // width: 100%;
        // max-width: 122px;
        // height: 108px;
        // margin-right: 24px;
        width: 100%;
        position: relative;
        padding-top: 55%;

        @media screen and (max-width:1536px) {
          padding-top: 68%;
        }

        @media screen and (max-width:1280px) {
          padding-top: 78%;
        }


        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  .download-icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background-color: rgba(0, 0, 0, 0.3); /* Smoky transparent background */
    border: 5px solid rgb(255 255 255 / 56%); /* Smoky circle border */
    border-radius: 50%;
    padding: 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    z-index: 1;
  }
  
  .download-icon-overlay:hover {
    // background-color: rgba(0, 0, 0, 0.5); /* Darker smoky effect on hover */
    border-color: rgba(251, 251, 251, 0.921); /* Darker border on hover */
  }
  
  .download-icon-overlay svg {
    fill: rgba(255, 255, 255, 0.5); /* Smoky white icon */
    width: 24px;
    height: 24px;
    transition: fill 0.3s ease;
  }
  
  .download-icon-overlay:hover svg path {
    fill: rgba(251, 251, 251, 0.921);/* Lighter smoky effect on hover */
  }

  .PadRight {
    padding-right: 70px;

    @media screen and (max-width:767px) {
      padding-right: 0;
    }
  }

  .LotsRules {
    overflow-x: auto;
    border: 0.64px solid #C1BBBB;
    max-height: 143px;
    border-radius: 3.5px;

    .OutputDetailsLots {
      margin: 0 0 4px 0;
      border-bottom: 1px solid rgba(211, 211, 211, 1);
      padding: 10px 15px;
      color: #7d7878;
      font-size: 16px;
      border-radius: 2.56px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.posirel {
  position: relative;
  margin-right: 23px;

  @media screen and (max-width:1536px) {
    margin-right: 8px;
  }

  &:last-child {
    margin-right: 0;
  }


  .CloseBtn {
    width: 20px;
    position: absolute;
    right: 0;
    background: #c51414;
    padding: 4px !important;
    border-radius: 50%;
    top: -4px;
    cursor: pointer;
    z-index: 1;
  }
}

.offence-form {
  .form-group {
    textarea {
      display: block;
    }

    .form-control {
      border: 0.8px solid #C1BBBB;
      border-radius: 3.2px;
      padding: 14px;
    }
  }

  button {
    width: 50%;
    max-width: 100%;
    border-radius: 4px;
    margin: 0 auto;
  }

  .green {
    margin: 0;
    max-width: 134px;
    background: #F8FFEF;
    border: 0.5px solid #66A90F;
    border-radius: 5px;
  }

  .lot-name-field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p {
      margin-bottom: 10px;
      margin-right: 10px;
      position: relative;

      &::after {
        content: ",";
        position: absolute;

      }

      &:last-child {
        &::after {
          content: "";
        }
      }
    }
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #7D7878;
    margin-bottom: 0;

  }
}

.modal-header {
  display: unset;
  position: relative;
  background: #fcff00;
}

.modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #1E1E1E;
  width: 100%;
}

.btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 1;
}

.modal-body {
  padding: 20px;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    padding: 20px 20px;
  }

  label {
    font-family: "Lato";
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #1e1e1e;
    display: block;
    margin-bottom: 7px;
  }

  .add-lots-feild {
    width: 90%;
  }

  .input-group-btn {
    width: 10%;

    .add-more {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      background: none;
      border: none;

      &:focus-visible {
        box-shadow: none;
      }
    }
  }

  .after-add-more {
    border: 1px solid #d9dce1;
    border-radius: 0.3125rem;

    input {
      border: none;
    }
  }

  .add-field {
    overflow-y: auto;
    height: 120px;
    border: 1px solid #d9dce1;
    border-radius: 0.3125rem;
    padding: 10px 13px;

    p {
      font-family: "Lato";
      font-weight: 400;
      font-size: 16px;
      color: #1e1e1e;
      margin-bottom: 10px;
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-bottom: 1px solid #d9dce1;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .file-upload {
    position: relative;
    overflow: hidden;
    border: 1px dashed rgb(179, 179, 179);
    white-space: nowrap;
    padding: 13px 35px;
    width: 100%;
    height: 70px;

    img {
      width: 25px;
      object-fit: contain;
      margin-right: 10px;
    }

    input {
      position: absolute;
      font-size: 50px;
      opacity: 0;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .DarkBtn {
    height: 44px;
    border-radius: 10px;

    @media screen and (max-width:767px) {
      width: 100%;
      border-radius: 10px;
    }
  }

  .dropdown {
    button {
      &:active {
        border: none;
      }
    }
  }



  header h1 {
    font-size: 12pt;
    color: #fff;
    background-color: #1ba1e2;
    padding: 20px;
  }

  article {
    width: 80%;
    margin: auto;
    margin-top: 10px;
  }

  .thumbnail {
    height: 100px;
    margin: 10px;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(208, 208, 208);
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #000000;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #000000;
  }
}

// Lot details css close

.pagination-block {
  display: flex;
  justify-content: center;
  margin: 23px 0;

  @media screen and (max-width:1280px) {
    margin: 18px 0;
  }

  ul {
    padding: 0 !important;

    li {
      min-width: 25px !important;
      height: 25px !important;

      a {
        height: 100%;
        line-height: 23px;
      }

      button {
        &:last-child {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }

        &:first-child {
          display: flex !important;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination-item {
    border: 1px solid #000;
    min-width: 30px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.green {
  background: rgba(16, 188, 54, 0.2);
  border-radius: 5px;
  color: #2F9E3A;
  padding: 5px 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  margin: 0 auto;
}

.paid-green {
  background: rgba(16, 188, 54, 0.2);
  border-radius: 5px;
  color: #2F9E3A;
  padding: 5px 40px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  margin: 0 auto;
}

.red {
  background: #F6CACA;
  border-radius: 5px;
  color: #F01313;
  padding: 5px 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  margin: 0 auto;
}

.voided-red{
  background: #F6CACA;
  border-radius: 5px;
  color: #F01313;
  padding: 5px 29px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 150px;
  margin: 0 auto;
}

.yellow {
  background: #bcb6102f;
  border-radius: 5px;
  color: #BC6210;
  padding: 5px 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 115px;
  margin: 0 auto;
}

.blue {
  background: #36A2F2;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 25px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  max-width: 115px;
  margin: 0 auto;
}









.DarkBtn {
  svg {
    margin-right: 7px;
  }
}

// ..................................................admin-dashboard....................................

.adminDashboard {
  position: relative;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;

    @media screen and (max-width:992px) {
      height: unset;
    }
  }

  .left-wrapper {
    margin-left: 0 !important;
    transform: translateX(0) !important;
    width: 100% !important;

    .DashboardSection {
      width: 100%;
      margin-left: 0;
    }
  }

  .DashboardSection {
    position: absolute;
    top: 124px;
    background: #F7F7F7;
    padding: 0 55px;
    margin-left: 308px;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 308px);
    height: calc(100vh - 124px);

    @media screen and (max-width:1536px) {
      top: 100px;
      height: calc(100vh - 100px);
    }

    @media screen and (max-width:1300px) {
      padding-left: 40px;
      padding-right: 40px;
      width: calc(100% - 250px);
      margin-left: 250px;
    }

    @media screen and (max-width:992px) {
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      margin-left: 0;
      height: auto;
    }

    @media screen and (max-width:767px) {
      top: 84px;
      padding-left: 20px;
      padding-right: 20px;
      min-height: calc(100vh - 84px);
    }

    .leftSection {
      width: 55%;
      padding: 30px 30px 0 0;


      @media screen and (max-width:1024px) {
        width: 100%;
        padding: 0;
      }

      @media screen and (min-width:1024px) and (max-width:1200px) {
        padding: 30px 20px 0 0;
      }

      @media screen and (max-width:1533px) {
        padding: 10px 20px 0 0;
      }

      @media screen and (max-width:992px) {
        height: auto;
        padding-right: 0;
        padding-top: 15px;
      }

      h4 {
        font-family: 'Lato';
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        color: #252525;

        @media screen and (max-width:1280px) {
          font-size: 20px;
        }
      }

      .statsblock {
        overflow-y: auto;
        border: 1px solid rgba(179, 179, 179, 0.5);
        border-radius: 10px;
        padding: 21px 50px;
        height: calc(100vh - 209px);

        @media screen and (max-width:1536px) {
          padding: 14px 30px;
          height: calc(100vh - 192px) !important;
        }

        @media screen and (max-width:1280px) {

          height: calc(100vh - 190px);
        }

        @media screen and (max-width:992px) {
          height: auto;
        }

        @media screen and (max-width:767px) {
          padding: 0;
          border: none;
          height: 100% !important;
          overflow: hidden;
        }

        .col {
          padding: 10px 15px;

          @media screen and (max-width:1280px) {
            padding: 11px;
          }

          .stats-list {
            background: #FFFFFF;
            box-shadow: 0.8px 0.8px 12.8px rgb(0 0 0 / 10%);
            border-radius: 8px;
            padding: 25px 25px;
            height: 100%;

            @media screen and (max-width:1746px) {
              padding: 28px;
            }

            @media screen and (max-width:1537px) {
              padding: 20px 28px;
            }

            @media screen and (max-width:767px) {
              padding: 17px;
            }


            h1 {
              font-family: 'Lato';
              font-weight: 800;
              font-size: 48px;
              line-height: 50px;
              color: #252525;
              margin-bottom: 33px;

              @media screen and (max-width:1536px) {
                margin-bottom: 25px;
              }

              @media screen and (max-width:1530px) {
                font-size: 40px;
              }

              @media screen and (max-width:1280px) {
                margin-bottom: 20px;
                line-height: 37px;
                font-size: 25px;
              }
            }

            p {
              font-family: 'Lato';
              font-weight: 700;
              font-size: 18px;
              line-height: 23px;
              color: #7D7878;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .rightSection {
      width: 45%;
      padding: 30px 0 0 30px;

      @media screen and (max-width:1024px) {
        width: 100%;
        padding: 24px 0 0 0;
      }

      @media screen and (min-width:1024px) and (max-width:1200px) {
        padding: 30px 0 0 20px;
      }

      @media screen and (max-width:1533px) {
        padding: 24px 0 0 20px;
      }

      @media screen and (max-width:992px) {
        height: 100%;
        padding: 24px 0 0 0;
      }

      .DarkBtn {
        margin-right: 0;

        img {
          width: 16px;
          margin-right: 10px;
        }
      }

      .search-input {
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        padding: 13px 15px;
        margin-bottom: 30px;

        @media screen and (max-width:1280px) {
          margin-bottom: 10px;
          padding: 8px 15px;
        }

        button {
          background: none;
          border: none;

          img {
            width: 19px;
            margin-right: 2px;

            @media screen and (max-width:1280px) {
              width: 16px;
            }
          }
        }

        input {
          background: none;
          border: none;
          width: 100%;

          &:focus {
            outline: none;
          }
        }
      }

      .nav-tabs {
        border-bottom: none;

        .nav-link.active {
          background: #f7f7f7;
          height: 102%;
          border-bottom: none;
          border-radius: 10px 10px 0 0;
          font-weight: 700;
          font-size: 24px;
          position: relative;
          z-index: 1;

          @media screen and (max-width:1280px) {
            font-size: 20px;
          }
        }

        .nav-link {
          height: 102%;
          border-bottom: none;
          font-family: 'Lato';
          color: #252525;
          font-weight: 400;
          font-size: 24px;

          @media screen and (max-width:1280px) {
            font-size: 20px;
          }

          &:hover {
            height: 102%;
          }
        }
      }

      .tab-content {
        height: calc(100% - 60px);
        position: relative;

        .tab-pane.active {
          border: 1px solid rgba(179, 179, 179, 0.5);
          padding: 33px;
          border-radius: 0 10px 10px 10px;
          height: 100%;

          @media screen and (max-width:1533px) {
            padding: 25px;
          }

          @media screen and (max-width:992px) {
            padding: 15px;
            height: 100%;
            margin-bottom: 15px;
          }
        }

        #menu1 {
          border-radius: 10px;

          .logo-img {
            width: 100%;
            max-width: 77px;
            margin-right: 24px;
            height: 77px;

            @media screen and (max-width:1536px) {
              max-width: 67px;
              height: 67px;
              margin-right: 20px;
            }


            img {
              max-width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .operator-block {
            li {
              a {
                padding: 30px 0;

                @media screen and (max-width:1280px) {
                  padding: 20px 0;
                }
              }
            }
          }
        }
      }


      .operator-block {
        list-style: none;
        overflow: auto;
        height: 500px;
        padding-right: 12px;

        @media screen and (max-width:1536px) {
          height: 400px;
        }

        @media screen and (max-width:1280px) {
          height: 320px;
        }

        li {
          a {
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid #BABABA;
            padding: 30px 0;

            @media screen and (max-width:1536px) {
              padding: 20px 0;
            }


            .logo-img {
              height: 77px;
              max-width: 77px;
              width: 100%;
              margin-right: 24px;

              @media screen and (max-width:1536px) {
                max-width: 67px;
                height: 67px;
                margin-right: 20px;
              }

              img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }

            .operator-list {
              width: 100%;

              h5 {
                font-family: 'Lato';
                font-weight: 500;
                font-size: 24px;
                line-height: 27px;
                color: #252525;
                margin-bottom: 10px;

                @media screen and (min-width:1024px) and (max-width:1533px) {
                  font-size: 20px;
                  margin-bottom: 5px;
                }

                @media screen and (max-width:767px) {
                  font-size: 18px;
                  margin-bottom: 7px;
                }
              }

              p {
                display: flex;
                align-items: center;
                font-family: 'Lato';
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #7D7878;
                margin: 0;

                @media screen and (min-width:1024px) and (max-width:1280px) {
                  font-size: 12px;
                  align-items: start;
                }

                @media screen and (max-width:767px) {
                  font-size: 12px;
                }

                img {
                  width: 100%;
                  max-width: 15px;
                  margin-right: 10px;

                  @media screen and (max-width:1536px) {
                    max-width: 11px;
                    margin-right: 5px;
                  }
                }
              }
            }
          }

          &:last-child {
            a {
              border-bottom: none;
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 7px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: rgb(208, 208, 208);
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #000000;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #000000;
    }
  }

  .adminreport-wrapper {
    position: absolute;
    top: 124px;
    transition: all 0.3s ease-in-out;
    width: calc(100% - 308px);
    padding: 25px 40px 0;
    // background: #F7F7F7;
    background: #ffffff;
    margin-left: 308px;
    height: calc(100vh - 124px);
    // @media screen and (max-width:1746px) {
    //   height: auto;
    // }
    // @media screen and (max-width:1536px) {
    //   top: 100px;
    //   height: calc(100vh - 100px);
    // }

    @media screen and (max-width:1300px) {
      padding: 18px 40px 0;
      width: calc(100% - 250px);
      margin-left: 250px;
    }

    @media screen and (max-width:992px) {
      width: 100%;
      margin-left: 0;
    }

    @media screen and (max-width:767px) {
      padding-left: 20px;
      padding-right: 20px;
      top: 84px;
      height: auto;
    }

    h4 {
      margin-bottom: 0;
      font-size: 24px;
    }

    .logo-img {
      margin-right: 20px;
      width: 77px;
      height: 77px;

      @media screen and (max-width:767px) {
        margin-right: 18px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;

      }
    }

    .logo-text {
      h5 {
        font-family: 'Lato';
        font-weight: 500;
        font-size: 24px;
        line-height: 27px;
        color: #252525;
        margin-bottom: 6px;

        @media screen and (max-width:1536px) {
          font-size: 20px;
          line-height: 20px;
        }

        @media screen and (max-width:767px) {
          font-size: 18px;
        }
      }

      p {
        margin: 0;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        color: #7D7878;
        white-space: nowrap;

        @media screen and (max-width:767px) {
          font-size: 13px;
        }

        img {
          width: 13px;
          margin-right: 10px;

          @media screen and (max-width:1280px) {
            width: 11px;
            margin-right: 6px;
          }
        }
      }
    }

    .date-picker-block {
      display: flex;
      align-items: center;

      @media screen and (max-width:767px) {
        flex-wrap: wrap;
      }


      .date-picker-list {
        background: rgba(255, 255, 255, 0) url(../images/ic-calender.svg);
        background-repeat: no-repeat;
        background-size: 55px;
        background-position: inherit;
        background-position-y: center;
        position: relative;
        height: 63px;
        width: 50%;

        &:first-child {
          position: relative;

          @media screen and (max-width:992px) {
            padding-right: 9px;
          }
        }

        &:last-child {
          margin-left: 50px;

          @media screen and (max-width:1536px) {
            margin-right: 0;
            margin-left: 30px;
          }
        }

        @media screen and (max-width:1280px) {
          height: 50px;
          background-size: 40px;
        }

        @media screen and (max-width:767px) {
          max-width: 160px;
        }

        @media screen and (min-width:768px) and (max-width:992px) {
          max-width: 50%;
        }

        label {
          padding-left: 80px;
          font-size: 16px;
          font-weight: 400;
          position: relative;

          @media screen and (max-width:1536px) {
            padding-left: 70px;
          }

          @media screen and (max-width:1280px) {
            padding-left: 60px;
            font-size: 13px;
          }

          @media screen and (max-width:992px) {
            padding-left: 53px;
          }

          &::after {
            content: "";
            position: absolute;
            background-image: url(../images/down-arrow.svg);
            width: 20px;
            background-repeat: no-repeat;
            height: 10px;
            top: 8px;
            right: -27px;

          }

          img {
            width: 13px !important;
            margin-left: 10px;
          }
        }

        .react-datepicker-wrapper {
          position: relative;
          top: -24px;
        }

        input {
          padding-left: 80px;
          border: none;
          height: 63px;
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          background: none;
          padding-top: 30px;
          cursor: pointer;

          @media screen and (max-width:1536px) {
            padding-left: 70px;
            padding-top: 25px;
          }

          @media screen and (max-width:1280px) {
            height: 50px;
            font-size: 13px;
            height: 47px;
            padding-left: 60px;
          }

          @media screen and (max-width:992px) {
            padding-left: 53px;
          }

          &:focus {
            outline: none;
          }
        }

        &:first-child {
          &::after {
            position: absolute;
            content: "";
            border: 1px solid #000;
            height: 50px;
            top: 7px;
            right: 0;

            @media screen and (max-width:1280px) {
              height: 38px;
            }

            @media screen and (max-width:1080px) {
              right: -5px;
            }
          }
        }

        .react-datepicker-popper {
          z-index: 9;
        }

        .react-datepicker__day--selected {
          background-color: #feffc5;
          color: #000;
        }

        .react-datepicker__navigation-icon {
          top: 6px;

          &::before {
            border-color: #000;
            border-width: 2px 2px 0 0;
          }
        }
      }

      .border-block {
        border-left: 1px solid #b3b3b380;
        border-bottom: 1px solid #b3b3b380;
        border-top: 1px solid #b3b3b380;
        padding-left: 20px;
        padding-top: 11px;
        padding-bottom: 11px;
        border-radius: 10px 0 0 10px;

        @media screen and (max-width:1280px) {
          padding-top: 12px;
          padding-bottom: 11px;
        }

        @media screen and (max-width:767px) {
          padding: 0;
          border: none;
          margin-bottom: 15px;
        }

      }

      .border-block-btn {
        border-right: 1px solid #b3b3b380;
        border-bottom: 1px solid #b3b3b380;
        border-top: 1px solid #b3b3b380;
        padding: 15px 0;
        margin-right: 20px;
        border-radius: 0 10px 10px 0;

        @media screen and (max-width:1280px) {
          padding: 14px 20px;
        }

        @media screen and (max-width:992px) {
          padding: 13px 10px;
          margin-right: 10px;
        }

        @media screen and (max-width:767px) {
          border: none;
          padding: 0;
          margin: 0;
        }
      }


    }

    .DarkBtn {
      @media screen and (max-width:1280px) {
        height: 45px;
        margin: 0;
        min-width: 125px;
        max-width: 125px;
      }

      &:first-child {

        @media screen and (min-width:992px) {
          margin: 0 20px;
        }
      }

      @media screen and (max-width:1180px) {
        margin: 20px 10px 10px;
      }

      img {
        width: 17px;
        margin-right: 10px;
      }
    }

    .report-list-wrapper {
      border: 1px solid rgba(179, 179, 179, 0.5);
      border-radius: 10px;
      padding-top: 22px;
      margin-top: 20px;

      @media screen and (max-width:1280px) {
        margin-top: 14px;
      }

      @media screen and (max-width:767px) {
        border: none;
        padding: 0;
      }

      .pd-block {
        padding: 0 24px 22px;

        @media screen and (max-width:1536px) {
          padding: 0 24px 18px;
        }

        @media screen and (max-width:767px) {
          padding: 0 0 25px 0;
        }

        .operators-title {
          padding-bottom: 30px;

          @media screen and (max-width:1280px) {
            padding-bottom: 20px;
          }

          button {
            @media screen and (max-width:767px) {
              max-width: 100%;
              width: 100%;
              margin: 25px 0 0;
            }
          }
        }

        .date-picker-block {
          .operator-btns {
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (max-width:767px) {
              width: 100%;
            }

            .border-block-btn {
              @media screen and (max-width:767px) {
                width: 100%;
                max-width: 50%;
                margin: 0 5px 0 0;
              }

              button {
                @media screen and (max-width:767px) {
                  width: 100%;
                  max-width: 100%;
                  margin: 25px 0 0;
                }
              }
            }

            .report-btn {
              @media screen and (max-width:767px) {
                width: 100%;
                max-width: 50%;
                margin: 25px 0 0 5px;
              }
            }
          }

        }
      }

      .nav-pills {
        border-bottom: 1px solid rgba(179, 179, 179, 0.5);
        // padding-top: 18px;

        .border-left-right {
          border-right: 0.5px solid #BABABA;
          border-left: 0.5px solid #BABABA;
        }

        .nav-item {
          .nav-link {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            color: #7D7878;
            padding: 0 16px;

            @media screen and (max-width:767px) {
              padding: 0 10px;
            }

            @media screen and (max-width:360px) {
              padding: 0 8px;
              font-size: 15px;
            }
          }

          .nav-link.active {
            background: none;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            color: #000000;

            @media screen and (max-width:360px) {
              font-size: 16px;
            }
          }
        }
      }

      .tab-content {
        .tab-pane {
          height: calc(100vh - 500px);
          padding: 24px 24px;
          position: relative;

          @media screen and (max-width:1280px) {
            height: calc(100vh - 385px);
          }

          @media screen and (max-width:767px) {
            padding: 20px 0;
          }

          &:last-child {
            border-radius: 0 0 10px 10px;

          }
        }

        .bart-tab {
          background: #fff;

          @media screen and (max-width:767px) {
            padding: 20px;
          }
        }

        #menu2 {
          background: #fff;
          // height: auto;

          @media screen and (max-width:767px) {
            padding: 20px;
          }
        }

        .table-block {
          background: #fff;
          border: 0.5px solid #cacaca;
          border-radius: 10px;
          height: calc(100vh - 545px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media screen and (max-width:1280px) {
            height: calc(100vh - 428px);
          }

          table {
            border-collapse: collapse;
            border-radius: 10px;
            background: #fff;

            thead {
              tr {
                background: #eff0f6;
                border-radius: 10px 10px 0px 0px;
                vertical-align: middle;

                th {
                  border: none;
                  font-family: "Lato";
                  padding: 19px 30px;
                  font-weight: 600;
                  font-size: 15px;
                  color: #7d7878;
                  white-space: nowrap;
                  text-align: center;

                  @media screen and (max-width:1536px) {
                    padding: 14px 20px;
                  }

                  @media screen and (max-width:1280px) {
                    font-size: 12px;
                  }

                  @media screen and (max-width:767px) {
                    padding: 17px 20px;
                  }
                }
              }
            }

            tbody {
              width: 100%;
              border-radius: 0 0 10px 10px;

              tr {
                vertical-align: middle;

                td {
                  font-weight: 500;
                  font-family: "Lato";
                  font-size: 15.7889px;
                  padding: 14px 30px;
                  border: none;
                  white-space: nowrap;
                  text-align: center;

                  @media screen and (max-width:1536px) {
                    padding: 6px 17px;
                  }

                  @media screen and (max-width:1280px) {
                    font-size: 12px;
                  }

                  @media screen and (max-width:767px) {
                    padding: 7px 20px;
                  }

                  button {
                    margin: 0 auto;
                    height: 47px;


                    @media screen and (max-width:1280px) {
                      height: 35px;
                      min-width: 100px;
                      font-size: 12px;
                    }

                    svg {
                      @media screen and (max-width:1280px) {
                        height: 13px;
                        width: 13px;
                      }
                    }
                  }
                }

                .tagging-types {
                  display: flex;
                  align-items: center;
                  justify-content: center;


                }
              }
            }
          }
        }
      }

      .operator-details {
        label {
          font-family: 'Lato';
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #7D7878;
          margin-bottom: 10px;
        }

        .operator-detail-list {
          background: #EEEEEE;
          border-radius: 2.56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          @media screen and (max-width:767px) {
            margin-bottom: 15px;
          }

          input {
            background: none;
            border: none;
            font-family: 'Lato';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #777777;
            width: 100%;
            width: 100%;
            padding: 15px 35px 15px 15px;
            margin: 4px;
            box-sizing: border-box;
            margin: 0;
            // z-index: 1;

            &:focus-visible {
              background: #fff;
              outline: 1px solid rgb(0, 0, 0);
              border-radius: 2.56px;
            }

          }

          img {
            width: 19px;
            position: absolute;
            right: 7px;
            top: 14px;
            color: #30A3F1;
          }
        }
      }

    }

    .report-block-wrapper {
      border: 1px solid rgba(179, 179, 179, 0.5);
      border-radius: 10px;
      padding-top: 22px;
      margin-top: 20px;

      @media screen and (max-width:1280px) {
        margin-top: 14px;
      }

      @media screen and (max-width:767px) {
        border: none;
        padding: 0;
      }

      .nav-pills {
        border-bottom: 1px solid rgba(179, 179, 179, 0.5);
        // padding-top: 18px;

        .border-left-right {
          border-right: 0.5px solid #BABABA;
          border-left: 0.5px solid #BABABA;
        }

        .nav-item {
          .nav-link {
            font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            color: #7D7878;
            padding: 0 16px;

            @media screen and (max-width:767px) {
              padding: 0 10px;
            }

            @media screen and (max-width:360px) {
              padding: 0 8px;
              font-size: 15px;
            }
          }

          .nav-link.active {
            background: none;
            border-radius: 0;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
            border-bottom: 4px solid #000;

            @media screen and (max-width:360px) {
              font-size: 16px;
            }
          }
        }
      }

      .tab-content {
        .tab-pane {
          height: calc(100vh - 275px);
          padding: 24px 24px;
          position: relative;

          @media screen and (max-width:1536px) {
            height: calc(100vh - 250px);
          }

          @media screen and (max-width:1280px) {
            height: calc(100vh - 230px);
          }

          @media screen and (max-width:767px) {
            padding: 20px 0 20px 0;
            // height: calc(100vh - 177px);
          }

          &:last-child {
            border-radius: 0 0 10px 10px;
          }
        }

        #menu2 {
          background: #fff;
        }

        .table-block {
          background: #fff;
          border: 0.5px solid #cacaca;
          border-radius: 10px;
          height: calc(100vh - 435px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @media screen and (max-width:1536px) {
            height: calc(100vh - 405px);
          }

          @media screen and (max-width:1280px) {
            height: calc(100vh - 370px);
          }

          table {
            border-collapse: collapse;
            border-radius: 10px;
            background: #fff;

            thead {
              tr {
                background: #eff0f6;
                border-radius: 10px 10px 0px 0px;
                vertical-align: middle;

                th {
                  border: none;
                  font-family: "Lato";
                  padding: 19px 30px;
                  font-weight: 600;
                  font-size: 15px;
                  color: #7d7878;
                  white-space: nowrap;
                  text-align: center;

                  @media screen and (max-width:1536px) {
                    padding: 14px 20px;
                  }

                  @media screen and (max-width:1280px) {
                    font-size: 12px;
                  }

                  @media screen and (max-width:767px) {
                    padding: 17px 20px;
                  }
                }
              }
            }

            tbody {
              width: 100%;
              border-radius: 0 0 10px 10px;

              tr {
                vertical-align: middle;

                td {
                  font-weight: 500;
                  font-family: "Lato";
                  font-size: 15.7889px;
                  padding: 14px 30px;
                  border: none;
                  white-space: nowrap;
                  text-align: center;

                  @media screen and (max-width:1536px) {
                    padding: 6px 20px;
                  }

                  @media screen and (max-width:1280px) {
                    font-size: 12px;
                  }

                  @media screen and (max-width:767px) {
                    padding: 7px 20px;
                  }

                  button {
                    margin: 0 auto;
                    height: 47px;


                    @media screen and (max-width:1280px) {
                      height: 35px;
                      min-width: 100px;
                      font-size: 12px;
                    }

                    svg {
                      @media screen and (max-width:1280px) {
                        height: 13px;
                        width: 13px;
                      }
                    }
                  }
                }

                .tagging-types {
                  display: flex;
                  align-items: center;
                  justify-content: center;


                }
              }
            }
          }
        }
      }

      .date-picker-block {
        .operator-btns {
          display: flex;
          align-items: center;
          width: 100%;

          .border-block-btn {
            @media screen and (max-width:767px) {
              width: 100%;
              max-width: 50%;
              margin: 0 5px 0 0;
            }

            button {
              @media screen and (max-width:767px) {
                width: 100%;
                max-width: 100%;
                margin: 25px 0 0;
              }
            }
          }

          .report-btn {
            @media screen and (max-width:767px) {
              width: 100%;
              max-width: 50%;
              margin: 25px 0 0 5px;
            }
          }
        }

      }
    }


    .secondndtab {
      .taggr-logo-img {
        width: 77px;
        height: 77px;
        margin-right: 12px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;

        }
      }

      .border-left-right {
        border-right: none !important;
      }
    }
  }

  .taggr-list-wrapper {
    height: calc(100vh - 84px) !important;
  }
}

.operator-modal {

  .modal-content {
    border-radius: 15.2px;
  }

  label {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #7D7878;
  }

  p {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #252525;

    img {
      width: 18px;
      margin-left: 8px;
    }
  }

  .date-picker {
    display: flex;
    align-items: center;

    // margin-bottom: 15px;
    img {
      max-width: 50px;
      width: 100%;
    }

    .date-picker-list {
      background: #ffffff00 url(../images/ic-calender.svg);
      background-repeat: no-repeat;
      background-size: 40px;
      background-position: left;
      background-position-y: center;

      label {
        padding-left: 50px;
      }

      input {
        height: 30px;
        width: 147px;
        border: none;
        padding-left: 50px;
        background: none;

        &:focus {
          outline: none;
        }
      }

      &:first-child {
        border-right: 1px solid #000;
        padding-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }
    }
  }

  button {
    img {
      width: 17px;
      margin-right: 9px;
    }
  }

  .switch-toggle {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 40px;

    p {
      font-family: 'Lato';
      font-weight: 600;
      font-size: 14px;
      color: #252525;
      margin: 0 9px 0 0;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 20px;
      margin: 0;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #D9D9D9;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: -2px;
      bottom: -3px;
      background-color: white;
      transition: 0.4s;
      border: 1px solid #979797;
    }

    input:checked+.slider {
      background-color: #2F9E3A;
    }

    input:focus+.slider {
      box-shadow: 0 0 1px #2F9E3A;
    }

    input:checked+.slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}

.table-responsive {
  border-radius: 10px;
  height: 100%;

  thead {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.adminCitations {
  position: relative;
  height: 100vh;

  .citationsSection {
    position: absolute;
    top: 124px;
    padding: 25px 55px 0;
    background: #F7F7F7;
    width: calc(100% - 308px);
    margin-left: 308px;
    transition: all 0.3s ease-in-out;
    min-height: calc(100vh - 124px);

    @media screen and (max-width:1536px) {
      top: 100px;
      height: auto;
      min-height: calc(100vh - 100px);
    }

    @media screen and (max-width:1300px) {
      padding-left: 40px;
      padding-right: 40px;
      width: calc(100% - 250px);
      margin-left: 250px;
    }

    @media screen and (max-width:992px) {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      margin-left: 0;
    }


    @media screen and (max-width:767px) {

      top: 84px;
      min-height: calc(100vh - 84px);
    }

    h4 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 24px;
      line-height: 20px;
      color: #252525;
      margin-bottom: 0;

      @media screen and (max-width:767px) {
        font-size: 20px;
      }
    }
  }

  .left-wrapper {
    margin-left: 0 !important;
    transform: translateX(0) !important;
    width: 100% !important;
  }

  .list-wrapper {
    border: 1px solid #B3B3B3;
    border-radius: 10px;
    padding: 24px 33px 0 24px;
    min-height: calc(100vh - 205px);
    margin-bottom: 12px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width:1536px) {
      min-height: calc(100vh - 185px);
    }

    @media screen and (max-width:992px) {
      margin-top: 15px;
    }

    @media screen and (max-width:767px) {
      padding: 15px;
      min-height: calc(100vh - 153px);
    }

    .search-input {
      background: #FFFFFF;
      border-radius: 10px;
      height: 57px;
      display: flex;
      align-items: center;
      padding: 15px 24px;
      width: 100%;

      @media screen and (max-width:1280px) {
        height: 50px;
      }

      @media screen and (max-width:767px) {
        margin: 0;
      }

      button {
        background: none;
        border: none;
        padding: 0;

        img {
          width: 17px;
          height: 17px;
          margin-right: 15px;
        }
      }

      input {
        background: none;
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .operator-block {
      list-style: none;

      li {
        &:last-child {
          a {
            border-bottom: none;
          }
        }

        a {
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid #BABABA;
          padding: 35px 0;

          @media screen and (max-width:1536px) {
            padding: 20px 0;
          }

          @media screen and (max-width:767px) {
            padding: 18px 0;
          }

          .logo-img {
            height: 77px;
            width: 100%;
            max-width: 77px;
            margin-right: 24px;

            @media screen and (max-width:1280px) {
              max-width: 70px;
            }

            img {
              max-width: 100%;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 1px solid #B3B3B3;
              border-radius: 50%;
            }
          }

          .taggr-logo-img {
            width: 100%;
            max-width: 77px;
            margin-right: 24px;
            height: 77px;

            @media screen and (max-width:1280px) {
              max-width: 65px;
              height: 65px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .operator-list {
            width: 100%;

            h5 {
              font-family: "Lato";
              font-weight: 500;
              font-size: 24px;
              line-height: 27px;
              color: #252525;
              margin-bottom: 10px;

              @media screen and (max-width:1280px) {
                font-size: 20px;
                margin-bottom: 7px;
              }

              @media screen and (max-width:767px) {
                font-size: 18px;
                margin-bottom: 7px;
              }
            }

            p {
              display: flex;
              align-items: center;
              font-family: "Lato";
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #7D7878;
              margin: 0;

              @media screen and (max-width:1280px) {
                font-size: 12px;
              }

              img {
                width: 100%;
                max-width: 15px;
                margin-right: 7px;

                @media screen and (max-width:1280px) {
                  max-width: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 55px;

    @media screen and (max-width:1300px) {
      padding: 24px 40px;
    }

    @media screen and (max-width:767px) {
      flex-wrap: wrap;
      padding: 0;
    }
  }

  .search-input {
    background: #FFFFFF;
    border-radius: 7px;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 15px 18px;
    width: 425px;
    border: 1px solid #ebebeb;

    @media screen and (max-width:767px) {
      margin: 15px 0 15px 0;
      padding: 15px;
    }

    input {
      background: none;
      border: none;
      margin-top: 2px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    button {
      border: none;
      background: none;
      padding: 0;

      img {
        width: 20px;
        margin-right: 10px;
      }
    }
  }

  .cirtation-wrapper {
    border-top: 0.5px solid #A0A0A0;
    padding: 15px 55px 0;

    @media screen and (max-width:1300px) {
      padding: 15px 40px 0;
    }

    @media screen and (max-width:767px) {
      border: none;
      padding: 0;
    }


    table {
      overflow: hidden;
      border-collapse: collapse;
      border-radius: 10px;
      width: 100%;

      thead {
        width: 100%;
        position: sticky;
        top: 0;

        tr {
          border-radius: 10px 10px 0px 0px;
          vertical-align: middle;

          th {
            border: none;
            font-family: "Lato";
            padding: 17px 30px 17px 30px;
            font-weight: 600;
            font-size: 14px;
            color: #7d7878;
            white-space: nowrap;
            text-align: center;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              @media screen and (max-width:767px) {
                padding: 15px;
              }
            }

            @media screen and (max-width:1532px) {
              padding: 15px 30px 15px 30px;
            }

            @media screen and (max-width:767px) {
              padding: 15px 58px 15px 0;
            }
          }
        }
      }

      tbody {
        width: 100%;
        border-radius: 0 0 10px 10px;

        tr {
          vertical-align: middle;

          td {
            font-weight: 500;
            font-family: "Lato";
            font-size: 14px;
            padding: 10px 30px;
            border: none;
            white-space: nowrap;
            text-align: center;

            &:last-child {
              .eyeBtn {
                min-width: 50px;
                height: 35px;
                align-items: center;
                display: flex;
                justify-content: center;
                background: #FFFFFF;
                border: 0.5px solid #000000;
                border-radius: 5px;
                // margin: 0 10px;
                margin: 0 5px;

                &:hover {
                  background: #000000;
                  border: 0.5px solid #000000;
                  color: #000000;
                }

                img {
                  max-width: 24px;
                  width: 100%;
                  margin-top: -2px;
                }


              }

              button {
                // margin: 0 10px;
                margin: 0 5px;
                background: #FFFFFF;
                border: 0.5px solid #000000;
                border-radius: 5px;
                color: #000000;
                height: 35px;
                width: 100%;
                min-width: 75px;

                &:hover {
                  background: #000000;
                  border: 0.5px solid #000000;
                  color: #fff;
                }


              }


            }

            // @media screen and (max-width:1532px) {
            //   padding: 13px 30px 15px 0;
            // }

            // @media screen and (max-width:767px) {
            //   padding: 15px 58px 15px 0;
            // }

          }
        }
      }
    }

    .lotText {
      min-width: 400px;
      width: 100%;
      white-space: break-spaces;
    }

    .full-width {
      width: 100%;
    }

    h6 {
      font-weight: 800;
      font-size: 18px;
      font-family: 'Lato';
      color: #1E1E1E;
      line-height: 30px;
      margin-bottom: 25px;

      @media screen and (max-width:767px) {
        margin-bottom: 10px !important;
      }

      img {
        width: 20px;
        margin-right: 8px;
        height: 20px;

      }
    }

  /* Default styles for larger screens */
  .proof-container {
    display: flex;
  }

  .left-block-proof,
  .right-block-proof {
    display: flex;
    flex-direction: column;
  }

  .left-block-proof{
    width: 65%;
  }

  .right-block-proof{
    align-items: center;
    width: 40%;
  }

  /* Media query for screens with a maximum width of 768px (adjust as needed) */
  @media (max-width: 992px) {
    .proof-container {
      display: block; /* Change to 'block' for mobile */
    }

    .left-block-proof,
    .right-block-proof {
      display: block; /* Change to 'block' for mobile */
      /* Add any additional styling needed for mobile layout */
    }
  }

  .proof-image{
    max-width: 100%;
    max-height: 100%;
  }
    /* ResponsiveButtons.css */

  /* Style for the container div */
  .custombutton-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  /* Style for the buttons */
  .custombutton {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid black;
    background-color: white;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
  }

  .custombutton:hover {
    background-color: black;  /* Change the background color on hover */
    color: white;
  }

  .custombutton:disabled {
    background: #FFFFFF;
    border: 0.5px solid black !important;
    color: #ccc !important;
    cursor: default;
  
    &:hover {
      background: #FFFFFF !important;
      border: 0.5px solid black !important;
      color: #ccc !important;
    }
  }

  .proof-button{
    background-color: #28b839 !important;
    color: 'white' !important;
    cursor: pointer !important;
  }

  .proof-button:hover {
    background-color: #00800f !important; /* Change the background color on hover */
  }

  /* Media query for responsiveness */
  @media (max-width: 992px) {
    // .custombutton-container {
    //   flex-direction: column;
    //   text-align: center;
    // }

    .custombutton {
      margin-top: 10px;
      font-size: 12px;
      width: 80px;
    }
  }

    .details-block-wrapper {
      @media screen and (max-width:767px) {
        margin-top: 20px;
      }
    }

    .details-wrapper-block {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;

      .dropbox-textarea {
        max-width: 400px;
        width: 100%;
        margin-right: 40px;

        @media screen and (max-width:1474px) {
          margin: 0 9px 20px;
        }

        label {
          display: block;
          margin-bottom: 10px;
          width: 100%;
        }

        textarea {
          width: 100%;
          background: #EEEEEE;
          border-radius: 2.56px;
          height: 100px;
          padding: 11px 15px;
          border: none;

          &:focus-visible {
            outline: none;
          }
        }
      }

      .details-list {
        max-width: 225px;
        margin-right: 40px;
        margin-bottom: 20px;

        @media screen and (max-width:1474px) {
          max-width: 211px;
          margin: 0 9px 20px;
        }

        @media screen and (max-width:767px) {
          max-width: 47%;
          margin: 1.5%;
        }

        label {
          font-family: 'Lato';
          margin-bottom: 10px;
          white-space: nowrap;
          font-weight: 600;
          font-size: 16px;
          color: #1E1E1E;
          white-space: break-spaces;
          word-break: break-word;
        }

        input {
          background: #EEEEEE;
          border-radius: 2.56px;
          padding: 11px 15px;
        }
      }
    }

    .images-wrapper {
      @media screen and (max-width:767px) {
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .citations_image {
        img {
          width: 260px;
          height: 140px;
          border-radius: 2px;
          object-fit: cover;

          @media screen and (max-width:767px) {
            width: 100%;
          }
        }
      }
    }

    h6 {
      font-weight: 800;
      font-size: 18px;
      font-family: 'Lato';
      color: #1E1E1E;
      line-height: 30px;
      margin-bottom: 25px;

      img {
        width: 20px;
        margin-right: 8px;
        height: 20px;

      }
    }

    .details-wrapper {
      p {
        font-family: 'Lato';
        font-weight: 600;
        font-size: 16px;
        color: #1E1E1E;
      }

      .col-lg-2 {
        margin: 0 30px 40px 0;

        label {
          font-family: 'Lato';
          margin-bottom: 10px;
          white-space: nowrap;
          font-weight: 600;
          font-size: 16px;
          color: #1E1E1E;
        }

        input {
          background: #EEEEEE;
          border-radius: 2.56px;
          padding: 11px 15px;
        }
      }
    }
  }
}

.InputDollorWrap {
  display: flex;
  align-items: center;
  padding: 0 11px;
  border: 1px solid #d9dce1;
  border-radius: 2px;
  width: 100%;
  border-radius: 0.3125rem;

  .DollorSign {
    line-height: normal;
  }

  .InputStyle {
    border: 0;
  }
}

.mobileTitle {
  @media screen and (max-width:767px) {
    margin-bottom: 15px !important;
  }
}

.marginBottom {
  margin-bottom: 22px;
}

.Error {
  color: red;
  font-size: 15px;
  text-align: left;
  margin: 4px 0 0 0;
}


.NotificationButtonWrapper {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999999 !important;

  .NotificationSuccessful {
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #10BC36;
      animation: border_anim 2s linear forwards;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
    }
  }

  .NotificationUnSuccessful {
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 999;
    padding: 10px 14px;
    position: relative;
    border: 0;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      border-bottom: 4px solid #10BC36;
      animation: border_anim 6s linear forwards;
      -webkit-animation: border_anim 6s linear forwards;
    }

    .Flexsec {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .SuccessIcon {
        width: 24px;
        margin-right: 14px;
      }

      h5 {
        margin-right: auto;
        font-weight: 700;
        font-size: 18px;
        color: #000;
      }

      .CloseIcon {
        margin-left: auto;
      }
    }

    p {
      text-align: left;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
}

@keyframes border_anim {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.dataText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    font-size: 19px;
    font-weight: 500;
  }
}

.loadingContainer {
  position: fixed !important;
}

.clickableLot {
  tr {
    &:hover {
      background: #eeeeee;
    }
  }
}


// 05-05-2023 css open
.StripTable td {
  border-bottom: 1px solid #dfdfdf !important;
}

.LabelStatus {
  width: 110px;
  padding: 5px 0px !important;
  display: inline-block;
}

.TextareaWrapper {
  textarea {
    width: 100%;
    height: 120px;
    border: 1px solid #ccc;
    resize: none;
    border-radius: 4px;
    padding: 10px;

    &:focus {
      outline: none;
    }
  }
}



.WhiteImg {
  display: none;
}

.eyeBtn {
  &:hover {
    .DarkImg {
      display: none;
    }

    .WhiteImg {
      display: block;
    }
  }
}

.ActionsButtons {
  min-width: 50px;
  height: 41px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;

  &:hover {
    background: #000000;
    border: 0.5px solid #000000;
    color: #000000;

    .DarkImg {
      display: none;
    }

    .WhiteImg {
      display: block;
    }
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.TermsText {
  margin: 0;

  a {
    color: #000;
    text-decoration: underline;
    font-weight: 500;
  }
}

// 05-05-2023 css close
.DarkEditBtnDisbled {
  min-width: 50px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;
  color: #000 !important;
  min-width: 50px !important;
  width: 50px;

  &:hover {
    background: #FFFFFF;
    border: 0.5px solid #000000;
    color: #fff !important;
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.DarkEditBtnDisbled:disabled {
  background: #FFFFFF;
  border: 0.5px solid #ccc !important;
  color: #ccc !important;

  &:hover {
    background: #FFFFFF !important;
    border: 0.5px solid #ccc !important;
    color: #ccc !important;
  }
}

.DarkEditBtnDisbledPayByCheck {
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;
  color: #000 !important;

  &:hover {
    background: #FFFFFF;
    border: 0.5px solid #000000;
    color: #fff !important;
  }

  img {
    width: 100%;
    margin-top: -2px;
  }
}

.DarkEditBtnDisbledPayByCheck:disabled {
  background: #FFFFFF;
  border: 0.5px solid #ccc !important;
  color: #ccc !important;

  &:hover {
    background: #FFFFFF !important;
    border: 0.5px solid #ccc !important;
    color: #ccc !important;
  }
}


.RefundBtnDisbled {
  min-width: 75px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;
  color: #000 !important;
  min-width: 75px !important;
  // width: 75px !important;

  &:hover {
    background: #FFFFFF !important;
    border: 0.5px solid #000000;
    color: #ccc !important;
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.RefundBtnDisbled:disabled {
  background: #FFFFFF;
  border: 0.5px solid #ccc !important;
  color: #ccc !important;

  &:hover {
    background: #FFFFFF !important;
    border: 0.5px solid #ccc !important;
    color: #ccc !important;
  }
}




.DarkBlackBtn {
  min-width: 50px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;
  color: #000 !important;

  &:hover {
    background: #000000;
    border: 0.5px solid #000000;
    color: #fff !important;
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.DarkGreenBtn {
  min-width: 50px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: green !important;
  border: 0.5px solid green !important;
  border-radius: 5px;
  margin: 0 5px;
  color: #fff !important;

  &:hover {
    background: green !important;
    border: 0.5px solid green !important;
    color: #fff !important;
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.DarkBlackBtnPayByCheck {
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin: 0 5px;
  color: #000 !important;

  &:hover {
    background: #000000;
    border: 0.5px solid #000000;
    color: #fff !important;
  }

  img {
    max-width: 24px;
    width: 100%;
    margin-top: -2px;
  }
}

.payoutscardSection {
  ul {
    // padding: 30px 0 40px;
    padding: 20px 0;
    border-bottom: none;
    gap: 30px;

    li {
      h6 {
        text-align: center;
        margin-bottom: 5px;
        font-size: 20px;
      }

      a {
        text-align: center;
        border: 1px solid rgb(233 233 233) !important;
        border-radius: 6px;

        &:hover {
          border: 1px solid #000 !important;
        }

        .payoutsCards {
          width: 100%;
          height: 100%;
          max-width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          h1 {
            font-size: 40px;
          }
        }
      }
    }

    .nav-link {
      width: 100%;
      max-width: 170px;
      height: 90px;
    }

    .nav-link.active {
      background: #000;
      border-radius: 6px;
      color: #fff;
      padding: 10px 15px;

      h1 {
        color: #ffffff;
      }
    }
  }

  .border-block {
    padding-bottom: 40px;

    input {
      padding: 8px 15px;
      border-radius: 5px;
      border: 1px solid #dedede;
      width: 100%;
    }

    button {
      background: none;
      border: none;
    }
  }

  table {
    border: 1px solid #dedede;
    text-align: center;
    border-radius: 10px;
    border-collapse: inherit;

    tr {
      vertical-align: middle;

      th,
      td {
        padding: 15px;
        border: none;
      }
    }

    .eyeBtn {
      max-width: 50px;
      height: 35px;
      align-items: center;
      display: flex;
      justify-content: center;
      background: #FFFFFF;
      border: 0.5px solid #000000;
      border-radius: 5px;
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        background: #000000;
        border: 0.5px solid #000000;
        color: #000000;
      }

      img {
        max-width: 24px;
        width: 100%;
        margin-top: -2px;
      }


    }
  }
}

.showid {
  max-width: 800px;
  margin: 30px auto 0;
  background: #fff;

  table {
    margin-bottom: 0;
  }

  .header-fixed>thead,
  .header-fixed>tbody,
  .header-fixed>thead>tr,
  .header-fixed>tbody>tr,
  .header-fixed>thead>tr>th,
  .header-fixed>tbody>tr>td {
    display: block;
  }

  .header-fixed>tbody>tr:after,
  .header-fixed>thead>tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
  }

  .header-fixed>tbody {
    overflow-y: auto;
    max-height: calc(100vh - 371px);
  }

  .header-fixed>tbody>tr>td,
  .header-fixed>thead>tr>th {
    width: 50%;
    float: left;

  }
}

.MargMinus {
  margin: 0 -10px;
}

.Outline {
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  padding: 10px 12px;
  width: calc(50% - 10px);
  height: auto;
  border: 1px solid #000;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline !important;
}
.RefundCloseHeader {
  background: none;
  border: 0;
  width: 24px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 23px;
}

.disputed-reason {
  resize: none;
  border: 1px solid gray;
  margin-top: 12px;
  font-size: 12px;
  width: 100%;
  padding: 10px;
  text-align: justify;
  width: 100%;
  background: #EEEEEE;
  border-radius: 2.56px;
  padding: 11px 15px;
  border: none;
  overflow-y: auto;

  &:focus-visible {
    outline: none;
  }
}

.field-with-confidence {
  display: flex;
  align-items: flex-end; 
  gap: 8px;
}

select.minimal {
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #ebebeb;
  border-radius: 7px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.3em 1em;

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.8em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 6px),
    calc(100% - 15px) calc(1em + 6px),
    calc(100% - 2.5em) 0.8em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}

.gap-20 {
  gap: 20px;
}

.matched-proof-images {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 30px 0px;
  flex-wrap: wrap;

  @media (min-width: 567px) {
    max-width: 800px;
    flex-wrap: nowrap;
  }
}


.stats-container-with-select {
  display: flex;

  select {
    margin: 0 0 10px 10px;
    padding-left: 10px;
    overflow-y: auto !important;
    display: block;
    max-height: 50px;
    max-width: 250px;
  }
  option {
    display: block;
  }
}

.report-filter {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
}
.report-filter:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none
}

.taggr-account-status {
  &.training-pending:before {
    background-color: #C9404D;
    border-color: #C42C3B;
    box-shadow: 0px 0px 4px 1px #C9404D;
  }

  &.training-completed:before {
    background-color: #00b028;
    border-color: #00b028;
    box-shadow: 0px 0px 4px 1px #00b028;
  }
  
  &.stripe-connected:before {
    background-color: purple;
    border-color: purple;
    box-shadow: 0px 0px 4px 1px purple;
  }

  &:before {
    content: ' ';
    display: inline-block;
    width: 9px;
    height: 9px;
    margin-left: 10px;
    margin-bottom: 3px;
    border: 1px solid #000;
    border-radius: 7px;
  }
}
.grid{
  display: grid !important;
}
// hide extra span tag which is coming on production environment
.react-datepicker__input-container span.react-datepicker__aria-live {
  display: none;
}