/*======================
    404 page
=======================*/


.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
    display: flex;
    justify-content: center;
    margin-top: 34px;
}

.page_404 img {
    width: 60%;
}

.page_container{
    width: 40%;
}


.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}
