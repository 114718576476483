.star-rating {
  font-size: 24px; /* Adjust size as needed */
  margin-bottom: 25px;
  text-align: center;
}

.star {
  color: #eae4c8; /* Default color for stars */
}

.yellow-star {
  color: #ffde5a; /* Color for filled stars */
}

.rating-align-text li {
  display: flex;
  flex-direction: column;
  align-items: center;
}