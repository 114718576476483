.min-h-screen {
  min-height: 100vh;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.max-w-md {
  max-width: 28rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-10 {
  margin-top: 2.5rem;
}

.p-6 {
  padding: 1.5rem;
}

.bg-white {
  background-color: #fff;
}

.rounded-lg {
  border-radius: 0.375rem;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  font-weight: 600;
}

.text-lg {
  font-size: 1.2rem;
  font-weight: 600;
}

.font-extrabold {
  font-weight: 800;
}

.mb-4 {
  margin-bottom: 1rem;
}

.text-gray-600 {
  color: #4b5563;
}

.block {
  display: block;
}

.text-sm {
  font-size: 0.875rem;
}

.font-medium {
  font-weight: 500;
}

.mt-1 {
  margin-top: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.border {
  border-width: 1px;
  border-style: solid;
}

.rounded-md {
  border-radius: 0.375rem;
}

.w-full {
  width: 100%;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.text-white {
  color: #fff;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.opacity-50 {
  opacity: 0.5;
}
