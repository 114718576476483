.selectors-container {
  display: ruby-text;
  // @media screen and (max-width:1536px) {
      
  // }
  @media screen and (max-width:767px) {
    display: flow;
  }
}

.selectors {
  margin-bottom: 10px !important;
  margin-right: 6px !important;
  min-width: 100px;
  max-width: 200px;
  @media screen and (max-width:767px) {
    display: flow;
    min-width: 100px;
    max-width: 350px;
    width: -webkit-fill-available;
  }
}