.container{
  margin-top: 20px !important;
  margin: auto;
  display: block;
  align-items: center;
  justify-content: center;
  //background-color: red;
  width: fit-content;
  border: 1px solid rgb(179, 179, 179);
  padding: 50px;
  border-radius: 5%;
}

.InputStyles{
width: 100% !important;
}

.file-upload {
  position: relative;
  overflow: hidden;
  border: 1px dashed rgb(179, 179, 179);
  white-space: nowrap;
  padding: 13px 35px;
  width: 100%;
  height: 120px;

  &:hover {
    border: 1px dashed rgb(179, 179, 179);
  }

  img {
    width: 25px;
    object-fit: contain;
    margin-right: 10px;
  }

  input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.large-image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}


  /* Style for the buttons */
  .custombutton {
    width: -webkit-fill-available;
    margin-top: 25px;
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid black;
    background-color: white;
    color: black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
  }

  .custombutton:hover {
    background-color: black;  /* Change the background color on hover */
    color: white;
  }

  .custombutton:disabled {
    background: #FFFFFF;
    border: 0.5px solid black !important;
    color: #ccc !important;
    cursor: default;
  
    &:hover {
      background: #FFFFFF !important;
      border: 0.5px solid black !important;
      color: #ccc !important;
    }
  }
  @media (max-width: 992px) {
  
    .custombutton {
      margin-top: 25px;
    }
  }