.splits-container {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;

  .splits-section-title {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 12px;
  }

  .splits-item {
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .split-party-title {
      font-size: 16px;
      color: #444;
      display: flex;
      align-items: center;
      flex-grow: 0.3;
    }
    .split-second-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 60%;
    }
    .split-edit-icon {
      font-size: 18px;
      color: #007bff;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: #0056b3;
      }
      &:disabled {
        background-color: #f0f0f0;
        cursor: not-allowed;
      }
    }

    .split-mode-switch-container {
      margin-left: 12px;
      display: flex;
      align-items: center;
    }

    .split-input-field {
      width: 80px;
      border-radius: 4px;
      padding: 6px;
      font-size: 14px;
      border: 1px solid #ccc;
      margin-top: 8px;
      text-align: center;

      &:disabled {
        background-color: #f0f0f0;
        cursor: not-allowed;
      }
    }

    .edit-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .action-icon {
        font-size: 16px;
        cursor: pointer;
        margin-left: 8px;
        transition: color 0.3s ease;

        &:hover {
          color: #007bff;
        }
      }

      .check-icon {
        color: #28a745;
      }

      .close-icon {
        color: #dc3545;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .splits-container {
    padding: 10px;
  }

  .splits-item {
    padding: 10px;
  }

  .split-party-title {
    font-size: 14px;
  }

  .split-input-field {
    font-size: 12px;
    width: 60px;
  }

  .splits-section-title {
    font-size: 18px;
  }
}
