.admin-citation-page {

  .date-picker-block {
    display: flex;
    align-items: center;
    
    @media screen and (max-width:767px) {
      flex-wrap: wrap;
    }
    
    
    .date-picker-list {
      background: rgba(255, 255, 255, 0) url(../../../../src/images/ic-calender.svg);
      background-repeat: no-repeat;
      background-size: 35px;
      background-position: inherit;
      background-position-y: center;
      position: relative;
      height: 43px;
      width: 40%;
      
      &:first-child {
        position: relative;
        
        @media screen and (max-width:992px) {
          padding-right: 9px;
        }
      }
      
      &:last-child {
        margin-left: 50px;
        
        @media screen and (max-width:1536px) {
          margin-right: 0;
          margin-left: 30px;
        }
      }
      
      @media screen and (max-width:1280px) {
        height: 50px;
        background-size: 40px;
      }
      
      @media screen and (max-width:767px) {
        max-width: 160px;
      }
      
      @media screen and (min-width:768px) and (max-width:992px) {
        max-width: 50%;
      }
      
      label {
        padding-left: 60px;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        
        @media screen and (max-width:1536px) {
          padding-left: 70px;
        }
        
        @media screen and (max-width:1280px) {
          padding-left: 60px;
          font-size: 13px;
        }
        
        @media screen and (max-width:992px) {
          padding-left: 53px;
        }
        
        &::after {
          content: "";
          position: absolute;
          background-image: url(../../../../src/images/down-arrow.svg);
          width: 20px;
          background-repeat: no-repeat;
          height: 10px;
          top: 8px;
          right: -27px;
          
        }
        
        img {
          width: 13px !important;
          margin-left: 10px;
        }
      }
      
      .react-datepicker-wrapper {
        position: relative;
        top: -24px;
      }
      
      input {
        padding-left: 60px;
        border: none;
        height: 50px;
        width: 100%;
        font-weight: 400;
        font-size: 13px;
        background: none;
        padding-top: 25px;
        cursor: pointer;
        
        @media screen and (max-width:1536px) {
          padding-left: 70px;
          padding-top: 25px;
        }
        
        @media screen and (max-width:1280px) {
          height: 40px;
          font-size: 13px;
          height: 47px;
          padding-left: 60px;
        }
        
        @media screen and (max-width:992px) {
          padding-left: 43px;
        }
        
        &:focus {
          outline: none;
        }
      }
      
      &:first-child {
        &::after {
          position: absolute;
          content: "";
          border: 1px solid #000;
          height: 45px;
          top: 0px;
          right: 0;
          
          @media screen and (max-width:1280px) {
            height: 38px;
          }
          
          @media screen and (max-width:1080px) {
            right: -5px;
          }
        }
      }
      
      .react-datepicker-popper {
        z-index: 9;
      }
      
      .react-datepicker__day--selected {
        background-color: #feffc5;
        color: #000;
      }
      
      .react-datepicker__navigation-icon {
        top: 6px;
        
        &::before {
          border-color: #000;
          border-width: 2px 2px 0 0;
        }
      }
    }
    
    .border-block {
      border-left: 1px solid #b3b3b380;
      border-bottom: 1px solid #b3b3b380;
      border-top: 1px solid #b3b3b380;
      padding-left: 10px;
      padding-top: 7px;
      padding-bottom: 7px;
      border-radius: 10px 0 0 10px;
      
      @media screen and (max-width:1280px) {
        padding-top: 3px;
        padding-bottom: 3px;
      }
      
      @media screen and (max-width:767px) {
        padding: 0;
        border: none;
        margin-bottom: 15px;
      }
      
    }
    
    .border-block-btn {
      border-right: 1px solid #b3b3b380;
      border-bottom: 1px solid #b3b3b380;
      border-top: 1px solid #b3b3b380;
      padding: 11px 0;
      margin-right: 0;
      padding-right: 0;
      height: max-content;
      margin-right: 10px;
      border-radius: 0 10px 10px 0;
      
      @media screen and (max-width:1280px) {
        padding: 10px 10px;
      }
      
      @media screen and (max-width:992px) {
        padding: 10px 10px;
        margin-right: 10px;
      }
      
      @media screen and (max-width:767px) {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    
    .btn{
      height: fit-content;
      width: fit-content;
    }
  }
  
}